/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { boolean, object, SchemaOf } from "yup";
import { ProposalStatus } from "../../common/Enums";
import { CancelledDetail } from "../../common/Types";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useValidation } from "../../hooks/UseValidation";
import { baseString, twoDecimalsNumber } from "../../utils/SchemaUtil";
import CheckField from "../Fields/CheckField";
import TextField from "../Fields/TextField";
import Textarea from "../General/Textarea";
import ConfirmationModal from "../Modals/ConfirmationModal";
import BottomBar from "../Navs/BottomBar";
import api from "../../services/PabloApiService";
import { GeneralResponse } from "../../common/Responses";

interface CancelProposal {
  forfeitureAmount: number;
  latePaymentInterest: number;
  legalFees: number;
  commission: number;
  miscExpense: number;
  miscProfit: number;
  remarks?: string;
  usePreviousPrice: boolean;
}

const schema: SchemaOf<CancelProposal> = object().shape({
  forfeitureAmount: twoDecimalsNumber("Forfeiture amount")
    .required()
    .label("Forfeiture amount"),
  latePaymentInterest: twoDecimalsNumber("Late payment interest")
    .required()
    .label("Late payment interest"),
  legalFees: twoDecimalsNumber("Legal fees").required().label("Legal fees"),
  commission: twoDecimalsNumber("Agent commission")
    .required()
    .label("Agent commission"),
  miscExpense: twoDecimalsNumber("Miscellaneous (Expense)")
    .required()
    .label("Miscellaneous (Expense)"),
  miscProfit: twoDecimalsNumber("Miscellaneous (Profit)")
    .required()
    .label("Miscellaneous (Profit)"),
  remarks: baseString().label("Remarks").max(500),
  usePreviousPrice: boolean()
    .required()
    .default(false)
    .label("Use previous price"),
});

interface CancelProposalFormProps {
  proposalId: number;
  proposalStatus: ProposalStatus;
  detail?: CancelledDetail;
  closeModal: Function;
  onCompleteCallback: () => void;
}

const CancelProposalForm: React.FC<CancelProposalFormProps> = (props) => {
  const { proposalId, proposalStatus, detail, closeModal, onCompleteCallback } =
    props;
  const loadingPage = useLoadingPageContext();
  const validation = useValidation<CancelProposal>(schema);
  const { register, watch, handleSubmit, errors } = validation;
  const cancelDetails = watch();
  const isCancelled = proposalStatus === "cancelled";

  const [usePreviousPrice, setUsePreviousPrice] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const onUsePreviousPriceChanged = (status: boolean) => {
    setUsePreviousPrice(status);
    validation.setValue(`usePreviousPrice`, status);
  };

  const cancel = async (id: number, body: any, isUpdate = false) => {
    const { success } = isUpdate
      ? await api.patch<GeneralResponse>(`/cancel/${id}/update`, body)
      : await api.post<GeneralResponse>(`/cancel/${id}`, body);

    return success;
  };

  const submitForm = async () => {
    let success = false;
    loadingPage.start();

    const cancellationBody = {
      forfeitureAmount: cancelDetails?.forfeitureAmount || 0,
      latePaymentInterest: cancelDetails?.latePaymentInterest || 0,
      legalFees: cancelDetails?.legalFees || 0,
      commission: cancelDetails?.commission || 0,
      miscExpense: cancelDetails?.miscExpense || 0,
      miscProfit: cancelDetails?.miscProfit || 0,
      remarks: cancelDetails?.remarks,
      usePreviousPrice: cancelDetails?.usePreviousPrice || false,
    };

    success = await cancel(proposalId, cancellationBody, isCancelled);

    loadingPage.end();
    setShowSuccess(success);
  };

  return (
    <>
      <div className="overflow-auto pb-5 h-full">
        <h2 className="capitalize">Cancellation Form</h2>

        <div className="col gap-5 mt-4 mb-2">
          <div className="row -mb-1 gap-4">
            <TextField
              id="forfeiture-amount"
              label="Forfeiture Amount"
              required={true}
              error={errors.forfeitureAmount?.message}
              defaultValue={detail?.forfeitureAmount}
              placeholder="Enter Value"
              {...register("forfeitureAmount")}
            />

            <TextField
              id="late-payment-interest"
              label="Late Payment Interest"
              required={true}
              error={errors.latePaymentInterest?.message}
              defaultValue={detail?.latePaymentInterest}
              placeholder="Enter Value"
              {...register("latePaymentInterest")}
            />
          </div>

          <div className="row -mb-1 gap-4">
            <TextField
              id="legal-fees"
              label="Legal Fees"
              required={true}
              error={errors.legalFees?.message}
              defaultValue={detail?.legalFees}
              placeholder="Enter Value"
              {...register("legalFees")}
            />

            <TextField
              id="agent-commission"
              label="Agent Commission"
              required={true}
              error={errors.commission?.message}
              defaultValue={detail?.commission}
              placeholder="Enter Value"
              {...register("commission")}
            />
          </div>

          <div className="row -mb-1 gap-4">
            <TextField
              id="misc-expense"
              label="Misc (expense)"
              required={true}
              error={errors.miscExpense?.message}
              defaultValue={detail?.miscExpense}
              placeholder="Enter Value"
              {...register("miscExpense")}
            />

            <TextField
              id="misc-profit"
              label="Misc (profit)"
              required={true}
              error={errors.miscProfit?.message}
              defaultValue={detail?.miscProfit}
              placeholder="Enter Value"
              {...register("miscProfit")}
            />
          </div>

          <div className="row -mb-1 gap-4">
            <Textarea
              id="cancelled-remarks"
              rows={5}
              label="Remarks"
              error={errors.remarks?.message}
              required={false}
              defaultValue={detail?.remarks}
              maxLength={500}
              {...validation.register("remarks", {
                setValueAs: (v) => v.trim(),
              })}
            />

            <div className="col mb-5 w-full tablet:w-[498px]">
              {!isCancelled && (
                <CheckField
                  key={`use-previous-price`}
                  label="Use Previous Price"
                  onChange={() => onUsePreviousPriceChanged(!usePreviousPrice)}
                  innerClassName="text-xs"
                  checked={usePreviousPrice}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <BottomBar innerClassName="pr-0 gap-4">
        <button
          id="submit-cancellation-button"
          className="secondary-button"
          onClick={() => closeModal()}
        >
          Close
        </button>
        <button
          id="submit-cancellation-button"
          className="primary-button"
          onClick={handleSubmit(() => setShowConfirmation(true))}
        >
          {isCancelled ? "Update" : "Submit"}
        </button>
      </BottomBar>

      <ConfirmationModal
        show={showConfirmation}
        dismissible={false}
        onHide={() => setShowConfirmation(false)}
        type="warning"
        title={`You're about to ${
          isCancelled ? "update the cancellation details of" : "cancel"
        }  this proposal`}
        onCancel={{
          text: "Close",
          action: () => setShowConfirmation(false),
        }}
        onConfirm={{
          text: "Submit",
          action: submitForm,
        }}
      />

      <ConfirmationModal
        show={showSuccess}
        dismissible={false}
        onHide={() => setShowSuccess(false)}
        type="success"
        title={`You have successfully ${
          isCancelled ? "updated the cancellation details of" : "cancelled"
        } this proposal.`}
        onConfirm={{
          text: "Back to CE Approval Listing",
          action: () => {
            closeModal();
            onCompleteCallback();
          },
        }}
      />
    </>
  );
};

export default CancelProposalForm;
