/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Path from "../../common/Path";
import BackButton from "../../components/Buttons/BackButton";
import EditProposalDetailsForm from "../../components/Forms/EditProposalDetailsForm";
import BottomBar from "../../components/Navs/BottomBar";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import { useMyProposalListContext } from "../../hooks/contexts/MyProposalListContext";
import { useScrollToError } from "../../hooks/UseScrollToError";
import { useUnload } from "../../hooks/UseUnload";
import { useValidation } from "../../hooks/UseValidation";
import {
  GenerateLettersForm,
  generateLettersSchema,
} from "../../schemas/BookingSchema";

const GenerateLettersEditPage: React.FC = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const validation = useValidation<GenerateLettersForm>(generateLettersSchema);
  const { scrollOnError } = useScrollToError<GenerateLettersForm>();
  const modal = useModalContext();
  const loadingPage = useLoadingPageContext();
  const proposalList = useMyProposalListContext();
  const { setDirty } = useUnload();

  const generateLettersForm = validation.watch();

  const handleSubmit = () => {
    setDirty(false);

    modal.confirmation({
      title: "You're about to update your proposal",
      description:
        "Please ensure that the details are correct before proceeding with your submission.",
      onConfirm: {
        text: "Submit",
        action: handleFormSubmission,
      },
    });
  };

  const handleFormSubmission = async () => {
    loadingPage.start();
    const { companyInfo, customerInfo, buyerType } = generateLettersForm;
    const formData = new FormData();

    /** Buyer Type */
    formData.append("buyer_type", buyerType);

    if (buyerType === "individual") {
      customerInfo
        ?.filter(() => true)
        ?.forEach((customer, index) => {
          /** Address Info */
          customer?.addressInfo?.addressLine1 &&
            formData.append(
              `buyers[${index}][address][line_1]`,
              customer.addressInfo.addressLine1
            );
          customer?.addressInfo?.addressLine2 &&
            formData.append(
              `buyers[${index}][address][line_2]`,
              customer.addressInfo.addressLine2
            );
          customer?.addressInfo?.city &&
            formData.append(
              `buyers[${index}][address][city]`,
              customer.addressInfo.city
            );
          customer?.addressInfo?.postCode &&
            formData.append(
              `buyers[${index}][address][postcode]`,
              customer.addressInfo.postCode
            );
          customer?.addressInfo?.state &&
            formData.append(
              `buyers[${index}][address][state]`,
              customer.addressInfo.state
            );
          customer?.addressInfo?.country &&
            formData.append(
              `buyers[${index}][address][country]`,
              customer.addressInfo.country
            );

          /** Customer Full Info */
          if (index === 0) {
            state?.customers?.[0]?.id
              ? formData.append(
                  `buyers[${index}][id]`,
                  state.customers[0].id.toString()
                )
              : customer?.id &&
                formData.append(`buyers[${index}][id]`, customer.id.toString());
          } else {
            customer?.id &&
              formData.append(`buyers[${index}][id]`, customer.id.toString());
          }

          customer?.birthDate &&
            formData.append(`buyers[${index}][birth_date]`, customer.birthDate);
          customer?.bumiputera &&
            formData.append(
              `buyers[${index}][bumiputera]`,
              customer.bumiputera === "yes" ? "1" : "0"
            );
          customer?.countryOfOrigin &&
            formData.append(
              `buyers[${index}][country]`,
              customer.countryOfOrigin
            );
          customer?.email &&
            formData.append(`buyers[${index}][email]`, customer.email);
          customer?.fullName &&
            formData.append(`buyers[${index}][full_name]`, customer.fullName);
          customer?.gender &&
            formData.append(`buyers[${index}][gender]`, customer.gender);
          customer?.identificationNumber &&
            formData.append(
              `buyers[${index}][identification_number]`,
              customer.identificationNumber
            );
          customer?.officePhoneCode &&
            formData.append(
              `buyers[${index}][office_phone_code]`,
              customer.officePhoneCode
            );
          customer?.officePhoneNumber &&
            formData.append(
              `buyers[${index}][office_phone_number]`,
              customer.officePhoneNumber
            );
          customer?.phoneCode &&
            formData.append(`buyers[${index}][phone_code]`, customer.phoneCode);
          customer?.phoneNumber &&
            formData.append(
              `buyers[${index}][phone_number]`,
              customer.phoneNumber
            );
          customer?.salutation &&
            formData.append(
              `buyers[${index}][salutation]`,
              customer.salutation
            );

          if (customer?.race) {
            customer.race !== "other" &&
              formData.append(`buyers[${index}][race]`, customer.race);

            customer?.otherRace &&
              customer.race === "other" &&
              formData.append(`buyers[${index}][race]`, customer.otherRace);
          }

          /** Customer Proof of Identity */
          customer?.attachments?.forEach((attachment, attachmentIndex) => {
            formData.append(
              `buyers[${index}][attachments][${attachmentIndex}][id]`,
              attachment.id
            );
            formData.append(
              `buyers[${index}][attachments][${attachmentIndex}][file]`,
              attachment.blob
            );
          });
        });
    }

    if (buyerType === "company") {
      /** Company Address Info */
      companyInfo?.addressInfo?.addressLine1 &&
        formData.append(
          `buyers[0][address][line_1]`,
          companyInfo.addressInfo.addressLine1
        );
      companyInfo?.addressInfo?.addressLine2 &&
        formData.append(
          `buyers[0][address][line_2]`,
          companyInfo.addressInfo.addressLine2
        );
      companyInfo?.addressInfo?.city &&
        formData.append(
          `buyers[0][address][city]`,
          companyInfo.addressInfo.city
        );
      companyInfo?.addressInfo?.postCode &&
        formData.append(
          `buyers[0][address][postcode]`,
          companyInfo.addressInfo.postCode
        );
      companyInfo?.addressInfo?.state &&
        formData.append(
          `buyers[0][address][state]`,
          companyInfo.addressInfo.state
        );
      companyInfo?.addressInfo?.country &&
        formData.append(
          `buyers[0][address][country]`,
          companyInfo.addressInfo.country
        );

      /** Company Info */
      companyInfo?.id &&
        formData.append(`buyers[0][id]`, companyInfo.id.toString());
      companyInfo?.companyName &&
        formData.append(`buyers[0][name]`, companyInfo.companyName);
      companyInfo?.companyRegistrationNumber &&
        formData.append(
          `buyers[0][registration_number]`,
          companyInfo.companyRegistrationNumber
        );
      companyInfo?.companyPhoneCode &&
        formData.append(`buyers[0][phone_code]`, companyInfo.companyPhoneCode);
      companyInfo?.companyPhoneNumber &&
        formData.append(
          `buyers[0][phone_number]`,
          companyInfo.companyPhoneNumber
        );

      /** Company Superform */
      companyInfo?.attachments?.forEach((attachment, index) => {
        formData.append(`buyers[0][attachments][${index}][id]`, attachment.id);
        formData.append(
          `buyers[0][attachments][${index}][file]`,
          attachment.blob
        );
      });

      /** Company Personal Info */
      companyInfo?.personalInfo?.forEach((personalInfo, index) => {
        personalInfo?.id &&
          formData.append(
            `buyers[0][personal_details][${index}][id]`,
            personalInfo.id.toString()
          );
        personalInfo?.title &&
          formData.append(
            `buyers[0][personal_details][${index}][title]`,
            personalInfo.title
          );
        personalInfo?.salutation &&
          formData.append(
            `buyers[0][personal_details][${index}][salutation]`,
            personalInfo.salutation
          );
        personalInfo?.fullName &&
          formData.append(
            `buyers[0][personal_details][${index}][full_name]`,
            personalInfo.fullName
          );
        personalInfo?.email &&
          formData.append(
            `buyers[0][personal_details][${index}][email]`,
            personalInfo.email
          );
        personalInfo?.phoneCode &&
          formData.append(
            `buyers[0][personal_details][${index}][phone_code]`,
            personalInfo.phoneCode
          );
        personalInfo?.phoneNumber &&
          formData.append(
            `buyers[0][personal_details][${index}][phone_number]`,
            personalInfo.phoneNumber
          );
        personalInfo?.identificationNumber &&
          formData.append(
            `buyers[0][personal_details][${index}][identification_number]`,
            personalInfo.identificationNumber
          );

        /** Company Personal Info Proof of Identity */
        personalInfo?.attachments?.forEach((attachment, attachmentIndex) => {
          formData.append(
            `buyers[0][personal_details][${index}][attachments][${attachmentIndex}][id]`,
            attachment.id
          );
          formData.append(
            `buyers[0][personal_details][${index}][attachments][${attachmentIndex}][file]`,
            attachment.blob
          );
        });
      });
    }

    const result = await proposalList.updateAndSend(state.id, formData);

    loadingPage.end();

    if (result) {
      toast.success("Letters have been generated and sent to your email.");
    } else {
      toast.error("Letter generation failed");
    }

    navigate(Path.myProposals);
  };

  useEffect(() => {
    scrollOnError(validation.errors);
  }, [validation.errors]);

  return (
    <>
      {/* page */}
      <div className="col justify-between min-h-screen h-max">
        <div className="mb-20">
          <BackButton text="Back to proposal summary" />

          <div className="h-14">
            <h2 className="capitalize mx-7">Generate 7 Letters</h2>
          </div>

          <EditProposalDetailsForm
            proposal={state}
            validation={validation}
            setDirty={setDirty}
            hasBooking={false}
          />
        </div>
      </div>

      <BottomBar>
        <button
          className="primary-button"
          onClick={validation.handleSubmit(handleSubmit)}
        >
          Generate 7 Letters
        </button>
      </BottomBar>
    </>
  );
};

export default GenerateLettersEditPage;
