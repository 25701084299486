/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { array, object, SchemaOf } from "yup";
import Path from "../../common/Path";
import {
  blockedPermissions,
  defaultPermissions,
  permissions,
  Role,
  roleName,
  roles,
} from "../../common/Permission";
import { User } from "../../common/Types";
import BackButton from "../../components/Buttons/BackButton";
import FormCard from "../../components/Cards/FormCard";
import CheckField from "../../components/Fields/CheckField";
import DropdownField from "../../components/Fields/DropdownField";
import TextField from "../../components/Fields/TextField";
import Line from "../../components/General/Line";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import BottomBar from "../../components/Navs/BottomBar";
import { useAuthContext } from "../../hooks/contexts/AuthContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useUserListContext } from "../../hooks/contexts/UserListContext";
import { useArray } from "../../hooks/UseArray";
import { useUnload } from "../../hooks/UseUnload";
import { useValidation } from "../../hooks/UseValidation";
import { baseString } from "../../utils/SchemaUtil";

interface UserForm {
  title: string;
  fullName: string;
  email: string;
  role: string;
  additionalPermissions: string[];
}

const UserFormPage: React.FC = () => {
  const { userId } = useParams();
  const { state } = useLocation();
  const user: User | null = state;

  const schema: SchemaOf<UserForm> = object().shape({
    title: baseString()
      .required()
      .max(50)
      .default(user?.title)
      .label("Job title"),
    fullName: baseString().required().default(user?.fullName),
    email: baseString()
      .email()
      .trim()
      .required()
      .matches(/@kskgroup.com$/, "Email must be a kskgroup domain.")
      .default(user?.email),
    role: baseString()
      .required()
      .default(user?.roles?.[0] ?? "catalyst"),
    additionalPermissions: array(),
  });

  const navigate = useNavigate();
  const userList = useUserListContext();
  const loadingPage = useLoadingPageContext();
  const validation = useValidation<UserForm>(schema);
  const auth = useAuthContext();
  const { setDirty } = useUnload();

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [additionalPermissions, dispatch] = useArray<string>(
    user?.permissions || []
  );

  useEffect(() => {
    validation.setValue("additionalPermissions", additionalPermissions);
  }, [additionalPermissions]);

  const submitForm = async (body: UserForm) => {
    let success = false;
    loadingPage.start();

    if (!userId) {
      success = await userList.store(body);
    } else {
      success = await userList.update(parseInt(userId), body);
    }

    loadingPage.end();
    setShowSuccess(success);
  };

  const toggleCheckbox = (index: number) => {
    setDirty(true);
    const updatingPermission = permissions[index];

    if (additionalPermissions.includes(updatingPermission)) {
      dispatch({ type: "remove", value: updatingPermission });
    } else {
      dispatch({ type: "add", value: updatingPermission });
    }
  };

  return (
    <>
      {/* page */}
      <div className="col h-full justify-between">
        <div>
          <BackButton text="Back to manage user" />

          <FormCard title={userId ? "Edit user" : "Add new user"}>
            <div className="gap-3 col">
              <h3>User details</h3>
              <div className="col mt-2">
                <div className="laptop:row w-full mb-5 gap-5">
                  <TextField
                    id="user-full-name"
                    label="Full name"
                    type="text"
                    defaultValue={user?.fullName}
                    error={validation.errors.fullName?.message}
                    onInput={() => setDirty(true)}
                    {...validation.register("fullName", {
                      setValueAs: (v) => v.trim(),
                    })}
                  />

                  <div className="mb-5" />

                  <TextField
                    id="user-email-address"
                    label="Email address"
                    type="email"
                    defaultValue={user?.email}
                    error={validation.errors.email?.message}
                    onInput={() => setDirty(true)}
                    {...validation.register("email", {
                      setValueAs: (v) => v.trim().toLowerCase(),
                    })}
                  />
                </div>

                <div className="laptop:row w-full mb-5 gap-5">
                  <TextField
                    id="user-job-title"
                    label="Job Title"
                    type="text"
                    defaultValue={user?.title}
                    error={validation.errors.title?.message}
                    onInput={() => setDirty(true)}
                    {...validation.register("title", {
                      setValueAs: (v) => v.trim(),
                    })}
                  />

                  <div className="mb-5" />

                  <DropdownField
                    disabled={auth.user?.id === parseInt(userId ?? "-1")}
                    id="role"
                    label="Role"
                    placeholder="Select a role"
                    onChangeCapture={() => {
                      setDirty(true);
                      dispatch({ type: "clear", value: "" });
                    }}
                    defaultValue={user?.roles?.[0] ?? "catalyst"}
                    error={validation.errors.role?.message}
                    {...validation.register("role", {
                      onChange: () => setDirty(true),
                    })}
                    items={roles.map((role) => {
                      return { text: roleName(role), value: role };
                    })}
                  />
                </div>
              </div>
            </div>

            <Line />

            <div className="gap-3 col">
              <h3>User permissions</h3>

              <div className="row gap-1 uppercase">
                <label>Permissions</label>
                <span className="text-dark-red">*</span>
              </div>

              <div className="grid grid-cols-3 gap-2">
                {permissions.map((permission, index) => {
                  const sameUser = auth.user?.id === parseInt(userId ?? "-1");
                  const role =
                    validation.watch("role") ?? user?.roles?.[0] ?? "catalyst";
                  const disabled = defaultPermissions(role as Role).includes(
                    permission
                  );
                  const blocked = blockedPermissions(role as Role).includes(
                    permission
                  );

                  return (
                    !blocked && (
                      <CheckField
                        key={`${permission}-${index}`}
                        label={permission}
                        disabled={disabled || sameUser}
                        onChange={() => toggleCheckbox(index)}
                        checked={
                          disabled || additionalPermissions.includes(permission)
                        }
                      />
                    )
                  );
                })}
              </div>
            </div>
          </FormCard>
        </div>

        <BottomBar>
          <button
            id="submit-user-button"
            className="primary-button"
            onClick={validation.handleSubmit(() => {
              setDirty(false);
              setShowConfirmation(true);
            })}
          >
            {userId ? "Save" : "Add"}
          </button>
        </BottomBar>
      </div>

      {/* modal */}
      <ConfirmationModal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        type="warning"
        title={
          userId
            ? "Are you sure you want to update the user details?"
            : "Are you sure you want to add this new user?"
        }
        onConfirm={{
          text: userId ? "Update" : "Add",
          action: validation.handleSubmit(submitForm),
        }}
      />

      <ConfirmationModal
        show={showSuccess}
        dismissible={false}
        onHide={() => setShowSuccess(false)}
        type="success"
        title={
          userId
            ? "User details has successfully been updated."
            : "You have successfully added this user."
        }
        onConfirm={{
          text: "Manage user",
          action: () => navigate(Path.users),
        }}
        onCancel={{
          text: "Back to main page",
          action: () => navigate(Path.main),
        }}
      />
    </>
  );
};

export default UserFormPage;
