import classNames from "classnames";
import React, { MouseEventHandler } from "react";
import PabloIcon from "../Icons/PabloIcon";

type PopUpLink = {
  isLink: boolean;
  href?: string;
};

interface PopUpItemProps {
  icon: string;
  label: string;
  onClick: MouseEventHandler;
  link?: PopUpLink;
  type?: string;
  imageClassName?: string;
}

const PopUpItem: React.FC<PopUpItemProps> = ({
  icon,
  label,
  onClick,
  imageClassName = "",
  link = { isLink: false },
  type = "svg",
}) => {
  const classes =
    "uppercase font-semibold text-xs px-4 pl-2 pr-10 h-10  hover:bg-ink-well rounded gap-2 row items-center select-none";

  // QA requested for links to be in <a> tag for better testing flow

  return link.isLink ? (
    <a
      href={link?.href}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
      className={classes}
    >
      <PabloIcon
        icon={icon}
        type={type}
        className={classNames(imageClassName)}
      />
      {label}
    </a>
  ) : (
    <span onClick={onClick} className={classes}>
      <PabloIcon
        icon={icon}
        type={type}
        className={classNames(imageClassName)}
      />
      {label}
    </span>
  );
};

export default PopUpItem;
