import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Permission } from "../common/Permission";
import Layout from "../components/Navs/Layout";
import AgencyFormPage from "../views/agencies/AgencyFormPage";
import AgencyListPage from "../views/agencies/AgencyListPage";
import AgentFormPage from "../views/agents/AgentFormPage";
import AgentListPage from "../views/agents/AgentListPage";
import BookingApprovalFormPage from "../views/booking-approvals/BookingApprovalFormPage";
import BookingApprovalListPage from "../views/booking-approvals/BookingApprovalListPage";
import ChangePasswordPage from "../views/auth/ChangePasswordPage";
import LoginPage from "../views/auth/LoginPage";
import SocialLoginCallbackPage from "../views/auth/SocialLoginCallbackPage";
import BookingFormPage from "../views/booking/BookingFormPage";
import GenerateLettersEditPage from "../views/generate-letters/GenerateLettersEditPage";
import LimboPage from "../views/generals/LimboPage";
import MyProposalListPage from "../views/proposals/MyProposalListPage";
import ProposalFormPage from "../views/proposals/ProposalFormPage";
import ProposalListPage from "../views/proposals/ProposalListPage";
import UnitListPage from "../views/units/UnitListPage";
import UserFormPage from "../views/users/UserFormPage";
import UserListPage from "../views/users/UserListPage";
import RequireAuth from "./RequireAuth";
import RequirePermission from "./RequirePermission";
import GenerateLettersViewPage from "../views/generate-letters/GenerateLettersViewPage";
import ManagementApprovalListPage from "../views/management-approvals/ManagementApprovalListPage";
import ManagementApprovalFormPage from "../views/management-approvals/ManagementApprovalFormPage";
import MyProposalSummaryPage from "../views/proposals/MyProposalSummaryPage";
import ProposalSummaryPage from "../views/proposals/ProposalSummaryPage";
import EditProposalFormPage from "../views/proposals/EditProposalFormPage";
import RebateCalculatorPage from "../views/calculator/RebateCalculatorPage";
import { useDeviceContext } from "../hooks/contexts/DeviceContext";
import ConvertToSoldFormPage from "../views/sold/ConvertToSoldFormPage";

const Router: React.FC = () => {
  const device = useDeviceContext();

  const can = (permissions: Permission[]) => {
    return <RequirePermission permissions={permissions} />;
  };

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/social/:provider/auth"
            element={<SocialLoginCallbackPage />}
          />

          <Route element={<RequireAuth />}>
            <Route path="/calculator" element={<RebateCalculatorPage />} />

            <Route element={can(["create-proposal"])}>
              <Route path="/units" element={<UnitListPage />} />
              <Route
                path="/units/proposal/new"
                element={<ProposalFormPage />}
              />
            </Route>

            <Route element={can(["my-proposals"])}>
              <Route path="/my-proposals" element={<MyProposalListPage />} />
              <Route
                path="/my-proposals/:proposalId"
                element={<MyProposalSummaryPage />}
              />
              <Route
                path="/my-proposals/:proposalId/edit"
                element={<EditProposalFormPage />}
              />
              <Route path="/my-proposals/book" element={<BookingFormPage />} />
              <Route
                path="/my-proposals/book/:proposalId/edit"
                element={<BookingFormPage />}
              />
            </Route>

            <Route element={can(["my-proposals", "booking-approval"])}>
              <Route
                path="/generate-letters/:proposalId/view"
                element={<GenerateLettersViewPage />}
              />
              <Route
                path="/generate-letters/:proposalId/view/ce"
                element={<GenerateLettersViewPage isCE={true} />}
              />
              <Route
                path="/generate-letters/:proposalId/edit"
                element={<GenerateLettersEditPage />}
              />
            </Route>

            <Route element={can(["tableau-dashboard"])}>
              <Route
                path="/tableau"
                element={<LimboPage type="under-construction" />}
              />
            </Route>

            <Route element={can(["management-approval"])}>
              <Route
                path="/management-approvals"
                element={<ManagementApprovalListPage />}
              />
              <Route
                path="/management-approvals/:proposalId"
                element={<ManagementApprovalFormPage />}
              />
            </Route>

            <Route element={can(["profit-margin"])}>
              <Route
                path="/profit-margins"
                element={<LimboPage type="under-construction" />}
              />
            </Route>

            <Route element={can(["exco-variables-listing"])}>
              <Route
                path="/exco-variables"
                element={<LimboPage type="under-construction" />}
              />
            </Route>

            <Route element={can(["proposal-listing"])}>
              <Route path="/proposals" element={<ProposalListPage />} />
              <Route
                path="/proposals/:proposalId"
                element={<ProposalSummaryPage />}
              />
              <Route
                path="/proposals/:proposalId/edit"
                element={<ProposalFormPage />}
              />
            </Route>

            <Route element={can(["reserved-extension"])}>
              <Route
                path="/reserved-extensions"
                element={<LimboPage type="under-construction" />}
              />
            </Route>

            <Route element={can(["booking-approval"])}>
              <Route
                path="/booking-approvals"
                element={<BookingApprovalListPage />}
              />
              <Route
                path="/booking-approvals/:proposalId"
                element={<BookingApprovalFormPage />}
              />
              <Route
                path="/booking-approvals/sold/:proposalId"
                element={<ConvertToSoldFormPage />}
              />
            </Route>

            <Route element={can(["manage-agent"])}>
              <Route path="/agencies" element={<AgencyListPage />} />
              <Route path="/agencies/new" element={<AgencyFormPage />} />
              <Route
                path="/agencies/:agencyId/edit"
                element={<AgencyFormPage />}
              />
              <Route path="/agents" element={<AgentListPage />} />
              <Route path="/agents/new" element={<AgentFormPage />} />
              <Route path="/agents/:agentId/edit" element={<AgentFormPage />} />
            </Route>

            <Route element={can(["manage-user"])}>
              <Route path="/users" element={<UserListPage />} />
              <Route path="/users/new" element={<UserFormPage />} />
              <Route path="/users/:userId/edit" element={<UserFormPage />} />
            </Route>

            <Route path="/change-password" element={<ChangePasswordPage />} />

            <Route
              path=""
              element={
                <Navigate
                  to={
                    device.isPortrait && device.isMobile
                      ? "/calculator"
                      : "/units"
                  }
                />
              }
            />

            <Route path="*" element={<LimboPage type="not-found" />} />
          </Route>
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default Router;
