/* eslint-disable react-hooks/exhaustive-deps */
import { Moment } from "moment";
import { useEffect, useState } from "react";
import { IFormSchema } from "../../common/Interfaces";
import {
  DisabledStages,
  disabledStages,
  PaymentStructureType,
} from "../../common/PaymentStructure";
import { FormErrors, PaymentStructure, Rebate } from "../../common/Types";
import { Validation } from "../../hooks/UseValidation";
import {
  ExpandableCard,
  ExpandableCardValidated,
} from "../Cards/ExpandableCard";
import RadioField from "../Fields/RadioField";
import { RadioGroup } from "../General/Radio";
import FlexibleInstalmentPaymentStructureAccordian from "./FlexibleInstalmentPaymentStructureAccordian";
import InstalmentPaymentStructureAccordian from "./InstalmentPaymentStructureAccordian";
import ScheduleHPaymentStructureAccordian from "./ScheduleHPaymentStructureAccordian";

interface PaymentStructureAccordianProps<T extends IFormSchema> {
  type: PaymentStructureType;
  onTypeChange: (selectedType: PaymentStructureType) => void;
  formErrors: FormErrors;
  grossSellingPrice?: number;
  nettSellingPrice: number;
  totalRebate: number;
  progressRebates: Rebate[];
  remainingRebate: number;
  hasPaymentStructureOpened: boolean;
  setFormErrors: Function;
  setProgressRebates: Function;
  setRemainingRebate: (rebate: number) => void;
  className?: string;
  hasDefaultValues?: boolean;
  tower: string;
  repaymentMonths: number;
  paymentStartDate: Moment;
  paymentEndDate: Moment;
  setRepaymentMonths: (months: number) => void;
  setPaymentStartDate: (startDate: Moment) => void;
  setPaymentEndDate: (endDate: Moment) => void;
  rebateType: string;
  validation: Validation<T>;
  defaultPaymentStructure?: PaymentStructure;
  detailsIndexes: number[];
  dispatchDetailsIndexes: Function;
}

const PaymentStructureAccordian: React.FC<
  PaymentStructureAccordianProps<any>
> = (props) => {
  const {
    type,
    onTypeChange,
    remainingRebate,
    nettSellingPrice,
    hasPaymentStructureOpened,
    setFormErrors,
    className,
    formErrors,
    grossSellingPrice,
    totalRebate,
    progressRebates,
    setProgressRebates,
    setRemainingRebate,
    hasDefaultValues,
    tower,
    repaymentMonths,
    paymentStartDate,
    paymentEndDate,
    setRepaymentMonths,
    setPaymentStartDate,
    setPaymentEndDate,
    rebateType,
    validation,
    defaultPaymentStructure,
    detailsIndexes,
    dispatchDetailsIndexes,
  } = props;

  const [validated, setValidated] = useState<ExpandableCardValidated>("empty");

  const resetFormErrors = () => {
    setFormErrors({
      scheduleH: "",
    });
  };

  useEffect(() => {
    if (hasPaymentStructureOpened && type === "schedule-h") {
      validation.setValue("paymentStructure.type", type);
      validation.clearErrors("paymentStructure");
      resetFormErrors();

      if (remainingRebate === 0) {
        progressRebates.some(
          (rebate) =>
            disabledStages.includes(rebate.stage as DisabledStages) &&
            rebate.value > 0
        )
          ? setValidated("warning")
          : setValidated("success");

        setFormErrors((prevErrors: FormErrors) => {
          return { ...prevErrors, scheduleH: "" };
        });
      } else if (remainingRebate > 0) {
        setValidated("error");
        setFormErrors((prevErrors: FormErrors) => {
          return {
            ...prevErrors,
            scheduleH: "Please apply all the remaining rebate",
          };
        });
      } else {
        setValidated("error");
        setFormErrors((prevErrors: FormErrors) => {
          return {
            ...prevErrors,
            scheduleH: "Rebate has exceeded the maximum rebate amount selected",
          };
        });
      }
    }
  }, [type, remainingRebate, hasPaymentStructureOpened]);

  return (
    <>
      <ExpandableCard
        value="payment-structure"
        title="Payment Structure"
        validated={validated}
        className={className}
        showRequired
      >
        <div className="px-8 py-4 mb-6 bg-white shadow rounded-sm border border-[#53565A33]">
          <h2>Type</h2>
          <div className="flex-wrap flex items-center justify-start mt-2 gap-x-6">
            <RadioGroup
              onChange={(value: string | number) =>
                onTypeChange(value as PaymentStructureType)
              }
              defaultValue={type}
            >
              <RadioField
                id="schedule-h-radio-button"
                key="schedule-h"
                label="Progressive Payment"
                value="schedule-h"
                innerClassName="pl-0"
              />
              <RadioField
                id="instalment-radio-button"
                key="instalment"
                label="Fixed Instalment"
                value="instalment"
                innerClassName="pl-0"
              />
              <RadioField
                id="flexible-instalment-radio-button"
                key="flexible-instalment"
                label="Flexible Instalment"
                value="flexible-instalment"
                innerClassName="pl-0"
              />
            </RadioGroup>
          </div>
        </div>

        {type === "instalment" ? (
          <InstalmentPaymentStructureAccordian
            validation={validation}
            setValidated={setValidated}
            tower={tower}
            nettSellingPrice={nettSellingPrice}
            repaymentMonths={repaymentMonths}
            paymentStartDate={paymentStartDate}
            paymentEndDate={paymentEndDate}
            setRepaymentMonths={setRepaymentMonths}
            setPaymentStartDate={setPaymentStartDate}
            setPaymentEndDate={setPaymentEndDate}
            defaultValues={
              {
                initialPayment:
                  defaultPaymentStructure?.initialPayment || undefined,
                instalmentPayment:
                  defaultPaymentStructure?.instalmentPayment || undefined,
                outstandingPayment:
                  defaultPaymentStructure?.outstandingPayment || undefined,
              } || undefined
            }
          />
        ) : type === "flexible-instalment" ? (
          <FlexibleInstalmentPaymentStructureAccordian
            validation={validation}
            grossSellingPrice={grossSellingPrice}
            setValidated={setValidated}
            detailsIndexes={detailsIndexes}
            dispatchDetailsIndexes={dispatchDetailsIndexes}
            defaultValues={defaultPaymentStructure?.details || undefined}
          />
        ) : (
          <ScheduleHPaymentStructureAccordian
            formErrors={formErrors}
            totalRebate={totalRebate}
            nettSellingPrice={nettSellingPrice}
            remainingRebate={remainingRebate}
            progressRebates={progressRebates}
            hasPaymentStructureOpened={hasPaymentStructureOpened}
            setFormErrors={setFormErrors}
            setProgressRebates={setProgressRebates}
            setRemainingRebate={setRemainingRebate}
            hasDefaultValues={hasDefaultValues}
            grossSellingPrice={grossSellingPrice}
            rebateType={rebateType}
          />
        )}
      </ExpandableCard>
    </>
  );
};

export default PaymentStructureAccordian;
