export default class Icon {
  static readonly agent = "agent";
  static readonly arrow = "arrow";
  static readonly bell = "bell";
  static readonly close = "close";
  static readonly collapseArrow = "collapse-arrow";
  static readonly convert = "convert";
  static readonly dashboard = "dashboard";
  static readonly remove = "delete";
  static readonly download = "download";
  static readonly edit = "edit";
  static readonly excoListing = "exco-listing";
  static readonly extend = "extend";
  static readonly eye = "eye";
  static readonly fillAgent = "fill-agent";
  static readonly fillBell = "fill-bell";
  static readonly fillDashboard = "fill-dashboard";
  static readonly fillExcoListing = "fill-exco-listing";
  static readonly fillMore = "fill-more";
  static readonly fillMyProposal = "fill-my-proposal";
  static readonly fillNewProposal = "fill-new-proposal";
  static readonly fillProfitMargin = "fill-profit-margin";
  static readonly fillProposalListing = "fill-proposal-listing";
  static readonly fillManagementApproval = "fill-management-approval";
  static readonly fillReferrer = "fill-referrer";
  static readonly fillReservedExtension = "fill-reserved-extension";
  static readonly fillUser = "fill-user";
  static readonly filter = "filter";
  static readonly grid = "grid";
  static readonly info = "info";
  static readonly list = "list";
  static readonly logout = "logout";
  static readonly minus = "minus";
  static readonly myProposal = "my-proposal";
  static readonly newProposal = "new-proposal";
  static readonly profitMargin = "profit-margin";
  static readonly proposalListing = "proposal-listing";
  static readonly managementApproval = "management-approval";
  static readonly referrer = "referrer";
  static readonly reservedExtension = "reserved-extension";
  static readonly user = "user";
  static readonly plus = "plus";
  static readonly pointer = "pointer";
  static readonly reject = "reject";
  static readonly search = "search";
  static readonly tick = "tick";
  static readonly withdraw = "withdraw";
  static readonly success = "success";
  static readonly warning = "warning";
}
