/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { createRef, useEffect, useState } from "react";
import Country from "../../common/Country";
import { Customer } from "../../common/Types";
import { capitalize, printAddress } from "../../utils/CommonUtils";
import Line from "../General/Line";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";

interface CustomerInfoSummaryProps {
  index: number;
  customer: Customer;
  summarized: boolean;
  viewImage: (blobPath: string | null) => void;
}

const CustomerInfoSummary: React.FC<CustomerInfoSummaryProps> = (props) => {
  const { index, customer, summarized } = props;
  const ref = createRef<HTMLDivElement>();
  const [showMoreCustomerInfo, setShowMoreCustomerInfo] =
    useState<boolean>(false);

  const toggleShowMore = () => {
    setShowMoreCustomerInfo(!showMoreCustomerInfo);
  };

  useEffect(() => {
    const element = ref.current;

    if (element && !summarized && showMoreCustomerInfo)
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }, [showMoreCustomerInfo]);

  return (
    <>
      <div
        ref={ref}
        className={classNames("px-5 pt-3", {
          "transition-all duration-300 overflow-hidden gradient-bg-to-b from-section-background bg-section-background":
            !summarized,
          "bg-section-background mb-2 pb-3": summarized,
          "max-h-[200px]": !showMoreCustomerInfo && !summarized,
          "to-section-background max-h-[1000px]": showMoreCustomerInfo,
        })}
      >
        <h4 className="uppercase mb-4">Customer Information {index + 1}</h4>

        <table className="table-fixed w-full bg-transparent">
          <thead>
            <tr className="h-6 border-none">
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Salutation
              </th>
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Full Name
              </th>
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Contact No.
              </th>
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Email
              </th>
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Country of Origin
              </th>
            </tr>
          </thead>

          <tbody>
            <tr className="h-6 border-none">
              <td className="text-sm font-bold p-0">{customer.salutation}</td>
              <td className="text-sm font-bold p-0">{customer.fullName}</td>
              <td className="text-sm font-bold p-0">
                {`+${customer.phoneCode}${customer.phoneNumber}`}
              </td>
              <td className="text-sm font-bold p-0">
                {customer?.email || `--`}
              </td>
              <td className="text-sm font-bold p-0">
                {Country.countryName(customer?.country)}
              </td>
            </tr>

            {!summarized && (
              <>
                <tr
                  className={classNames("h-6 border-none", {
                    "opacity-40": !showMoreCustomerInfo,
                    "opacity-100": showMoreCustomerInfo,
                  })}
                >
                  <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                    Identification No.
                  </th>
                  <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                    Gender
                  </th>
                  <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                    Office No.
                  </th>
                  <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                    Race
                  </th>
                  <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                    Date of Birth
                  </th>
                </tr>

                <tr
                  className={classNames(
                    "h-6 border-none transition-all ease-in-out",
                    {
                      "opacity-10": !showMoreCustomerInfo,
                    }
                  )}
                >
                  <td className="text-sm font-bold px-0 pt-3">
                    {customer?.identificationNumber || "--"}
                  </td>
                  <td className="text-sm font-bold px-0 pt-3">
                    {customer?.gender ? capitalize(customer.gender) : "--"}
                  </td>
                  <td className="text-sm font-bold px-0 pt-3">
                    {customer.officePhoneCode && customer.officePhoneNumber
                      ? `+${customer.officePhoneCode}${customer.officePhoneNumber}`
                      : "--"}
                  </td>
                  <td className="text-sm font-bold px-0 pt-3">
                    {customer?.race ? capitalize(customer?.race) : "--"}
                  </td>
                  <td className="text-sm font-bold px-0 pt-3">
                    {customer?.birthDate || "--"}
                  </td>
                </tr>

                <tr
                  className={classNames("h-6 border-none", {
                    "opacity-40": !showMoreCustomerInfo,
                    "opacity-100": showMoreCustomerInfo,
                  })}
                >
                  <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                    Bumiputera Status
                  </th>
                  <th />
                  <th />
                  <th />
                  <th />
                </tr>

                <tr
                  className={classNames(
                    "h-6 border-none transition-all ease-in-out",
                    {
                      "opacity-10": !showMoreCustomerInfo,
                    }
                  )}
                >
                  <td className="text-sm font-bold px-0 pt-3 pb-6">
                    {(customer.bumiputera ? "Yes" : "No") || "--"}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </>
            )}
          </tbody>
        </table>

        {!summarized && (
          <div
            className={classNames({
              "opacity-10": !showMoreCustomerInfo,
            })}
          >
            {customer.address && (
              <table className="table-fixed w-full bg-transparent -mt-2">
                <thead>
                  <tr className="h-6 border-none">
                    <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                      Address
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="h-6 border-none">
                    <td className="text-sm font-bold px-0 pb-6">
                      {printAddress(customer.address)}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            {(customer.attachments ?? []).length > 0 && (
              <>
                <Line className="mt-0 mb-4" />

                <div
                  className={classNames(
                    "font-normal uppercase transition-all ease-in-out",
                    {
                      "opacity-10": !showMoreCustomerInfo,
                    }
                  )}
                >
                  <h4 className="uppercase mb-4 text-sm">Proof of Identity</h4>

                  {customer?.attachments?.map((attachment, attachmentIndex) => (
                    <div
                      key={`customer-info-summary-image-${attachmentIndex}`}
                      className="row flex justify-between items-center h-[100px] my-3 text-xs "
                    >
                      <div className="row flex items-center">
                        <img
                          src={attachment.path}
                          onClick={() => props.viewImage(attachment.path)}
                          className="border border-[#A9695B33] object-contain w-[100px] h-[100px] cursor-pointer"
                          alt={`customer-${attachmentIndex}-attachments`}
                        />

                        <a
                          className="cursor-pointer pl-16"
                          href={attachment.path}
                          rel="noreferrer"
                          target="_blank"
                          download
                        >
                          <PabloIcon
                            icon={Icon.download}
                            className="gray-icon"
                          />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {!summarized && (
        <>
          <div
            className="text-xs text-center cursor-pointer bg-section-background mb-2"
            onClick={() => toggleShowMore()}
          >
            <div className="hover:opacity-40 transition duration-300 bg-section-background py-4">
              <span>{showMoreCustomerInfo ? "Show Less" : "Show More"}</span>
              <PabloIcon
                icon={Icon.collapseArrow}
                className={classNames(
                  "w-[20px] h-[20px] black-icon mx-auto select-none",
                  {
                    "transform -rotate-90 transition-transform duration-500":
                      !showMoreCustomerInfo,
                    "transform rotate-90 transition-transform duration-500":
                      showMoreCustomerInfo,
                  }
                )}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomerInfoSummary;
