import { SoldDetail } from "../../common/Types";
import PaymentInfoSummary from "./PaymentInfoSummary";

interface SoldInfoSummaryProps {
  soldDetail: SoldDetail;
  viewImage: (blobPath: string | null) => void;
}

const SoldInfoSummary: React.FC<SoldInfoSummaryProps> = (props) => {
  const { soldDetail, viewImage } = props;

  return (
    <>
      <div className="px-5 pb-5 pt-3 mb-2 bg-section-background">
        <h4 className="uppercase mb-4">Sold Information</h4>

        <table className="table-fixed w-full bg-transparent">
          <thead>
            <tr className="h-6 border-none">
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                SPA Signed Date
              </th>
              <th className="min-w-[250px] desktop:w-[250px]" />
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                1st 10% Paid Date
              </th>
              <th className="min-w-[250px] desktop:w-[250px]" />
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Sold Date
              </th>
            </tr>
          </thead>

          <tbody>
            <tr className="h-6 border-none">
              <td className="text-sm font-bold px-0">
                {soldDetail.spaSignedAt}
              </td>
              <td />
              <td className="text-sm font-bold px-0">{soldDetail.paidAt}</td>
              <td />
              <td className="text-sm font-bold px-0">{soldDetail.soldAt}</td>
            </tr>
          </tbody>
        </table>

        <PaymentInfoSummary
          name="sold-info"
          paymentDetails={soldDetail?.paymentDetails}
          viewImage={viewImage}
        />

        <div className="flex flex-wrap justify-start mt-6 mb-4 row items-center gap-4">
          <h4 className="uppercase">Catalysts Involved</h4>
        </div>

        <table className="table-fixed w-full bg-transparent">
          <thead>
            <tr className="h-6 border-none">
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px] uppercase">
                Name
              </th>
              <th className="min-w-[250px] desktop:w-[250px]" />
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px] uppercase">
                Email
              </th>
              <th className="min-w-[250px] desktop:w-[250px]" />
              <th className="min-w-[250px] desktop:w-[250px]" />
            </tr>
          </thead>

          <tbody>
            {soldDetail.users.map((user) => {
              return (
                <tr className="h-6 border-none">
                  <td className="text-sm font-bold px-0">{user.fullName}</td>
                  <td />
                  <td className="text-sm font-bold px-0">{user.email}</td>
                  <td />
                  <td />
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="flex flex-wrap justify-start mt-6 mb-4 row items-center gap-4">
          <h4 className="uppercase">Conversion Information</h4>
        </div>

        <table className="table-fixed w-full bg-transparent">
          <thead>
            <tr className="h-6 border-none">
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px] uppercase">
                Converted By
              </th>
              <th className="min-w-[250px] desktop:w-[250px]" />
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px] uppercase">
                Converted At
              </th>
              <th className="min-w-[250px] desktop:w-[250px]" />
              <th className="min-w-[250px] desktop:w-[250px]" />
            </tr>
          </thead>

          <tbody>
            <tr className="h-6 border-none">
              <td className="text-sm font-bold px-0">
                {soldDetail.convertedBy.fullName}
              </td>
              <td />
              <td className="text-sm font-bold px-0">
                {soldDetail.convertedAt}
              </td>
              <td />
              <td />
            </tr>
          </tbody>
        </table>

        {soldDetail?.remarks && (
          <div className="flex flex-wrap justify-start mt-8 mb-4 row items-center gap-4">
            <h4 className="uppercase">Sold Remarks</h4>

            <div className="w-full">
              <span className="text-sm font-bold">{soldDetail.remarks}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SoldInfoSummary;
