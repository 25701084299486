import { AxiosError, AxiosResponse } from "axios";
import { camelizeKeys } from "humps";
import { toast } from "react-toastify";
import Path from "../common/Path";
import { Response } from "../common/Types";
import { ApiClient } from "./ApiClient";

class PricingEngineApiService extends ApiClient {
  // TODO : Remove this method once Yin Yin change `/return_all_unit_info` response format
  protected onResponse<T>(response: AxiosResponse): Response<T> {
    if (
      response.data &&
      response.headers["content-type"] === "application/json" &&
      response.config.url !== "/return_all_unit_info"
    ) {
      response.data = camelizeKeys(response.data);
    }

    return { data: response.data, success: true };
  }

  protected onErrorResponse<T>(error: AxiosError): Response<T> {
    if (error.response?.status === 401) {
      const redirectUrl = `${window.location.origin}${Path.login}?expired=true`;
      window.location.href = redirectUrl;
    } else {
      toast.error(
        error.response?.data?.message ?? "Something went wrong on PE side."
      );
    }

    return super.onErrorResponse(error);
  }
}

const api = new PricingEngineApiService({
  baseUrl: process.env.REACT_APP_PRICING_ENGINE_API_BASE_URL,
  params: {
    auth: process.env.REACT_APP_PRICING_ENGINE_AUTH,
    methodArn: process.env.REACT_APP_PRICING_ENGINE_METHOD_ARN,
  },
});

export default api;
