import { createContext, useContext, useState } from "react";
import { proposalStatusName } from "../../common/Enums";
import {
  IndexProposalResponse,
  ProposalHandlerResponse,
  ShowProposalResponse,
  StoreProposalResponse,
} from "../../common/Responses";
import { ApprovalOption, Proposal, SalesRep } from "../../common/Types";
import api from "../../services/PabloApiService";

type BookingApprovalListContextType = {
  proposals: Proposal[];
  handlers: SalesRep[];
  isLoading: boolean;
  index: (append?: Array<string>) => Promise<boolean>;
  getHandlers: () => Promise<boolean>;
  show: (id: number) => Promise<Proposal | undefined>;
  search: (keyword: string) => void;
  update: (action: ApprovalOption, id: number, body?: any) => Promise<boolean>;
  convertToSold: (id: number, body: any) => Promise<boolean>;
};

const defaultContext: BookingApprovalListContextType = {
  proposals: [],
  handlers: [],
  isLoading: true,
  index: async (append?: Array<string>) => false,
  getHandlers: async () => false,
  show: async (id: number) => undefined,
  search: (keyword: string) => {},
  update: async (action: ApprovalOption, id: number, body?: any) => false,
  convertToSold: async (id: number, body: any) => false,
};

const BookingApprovalListContext =
  createContext<BookingApprovalListContextType>(defaultContext);

export const useBookingApprovalListContext = () =>
  useContext(BookingApprovalListContext);

export const BookingApprovalListContextProvider: React.FC = ({ children }) => {
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [tempProposals, setTempProposals] = useState<Proposal[]>([]);
  const [handlers, setHandlers] = useState<SalesRep[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const index = async (): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.get<IndexProposalResponse>(
      "/booking-approvals"
    );

    if (success) {
      setProposals(data.proposals);
      setTempProposals(data.proposals);
    }

    setLoading(false);

    return true;
  };

  const getHandlers = async (): Promise<boolean> => {
    const { data, success } = await api.get<ProposalHandlerResponse>(
      "/handlers"
    );

    if (success) {
      setHandlers(data.data);
    }

    return true;
  };

  const show = async (id: number): Promise<Proposal | undefined> => {
    setLoading(true);

    const { data, success } = await api.get<ShowProposalResponse>(
      `/booking-approvals/${id}`
    );

    setLoading(false);

    return success ? data.proposal : undefined;
  };

  const search = (keyword: string) => {
    if (keyword) {
      const formattedKeyword = keyword.trim().toLowerCase();
      const results = tempProposals.filter((proposal) => {
        const sameName = proposal?.customers?.some((customer) => {
          return customer.fullName.toLowerCase().includes(formattedKeyword);
        });
        const sameCompanyName = proposal?.companies?.some((company) => {
          return company.name.toLowerCase().includes(formattedKeyword);
        });
        const sameUnitNo = proposal?.unit?.number
          .toLowerCase()
          .includes(formattedKeyword);
        const sameStatus = proposalStatusName(proposal.status || "")
          .toLowerCase()
          .includes(formattedKeyword);
        return sameName || sameCompanyName || sameUnitNo || sameStatus;
      });

      setProposals(results);
    } else {
      setProposals(tempProposals);
    }
  };

  const update = async (
    action: ApprovalOption,
    id: number,
    body?: any
  ): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.patch<StoreProposalResponse>(
      `/booking-approvals/${id}/${action}`,
      body
    );

    if (success) {
      let temp = proposals.slice();
      const updatingProposal = temp.filter((proposal) => proposal.id === id)[0];
      const proposalIndex = temp.indexOf(updatingProposal);
      temp[proposalIndex] = data.proposal;
      setProposals(temp);
    }

    setLoading(false);

    return success;
  };

  const convertToSold = async (
    id: number,
    body: FormData
  ): Promise<boolean> => {
    setLoading(true);

    const { success } = await api.postFormData<StoreProposalResponse>(
      `/booking-approvals/${id}/sold?_method=put`,
      body
    );

    setLoading(false);

    return success;
  };

  return (
    <BookingApprovalListContext.Provider
      value={{
        proposals,
        handlers,
        isLoading,
        index,
        getHandlers,
        show,
        search,
        update,
        convertToSold,
      }}
    >
      {children}
    </BookingApprovalListContext.Provider>
  );
};
