/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { object, SchemaOf } from "yup";
import Path from "../../common/Path";
import {
  ApprovalOption,
  Booking,
  Company,
  Customer,
  OpportunitySource,
  PEUnit,
  Proposal,
  SalesPackage,
  Solicitor,
} from "../../common/Types";
import BackButton from "../../components/Buttons/BackButton";
import FormCard from "../../components/Cards/FormCard";
import DropdownField from "../../components/Fields/DropdownField";
import Line from "../../components/General/Line";
import Textarea from "../../components/General/Textarea";
import BottomBar from "../../components/Navs/BottomBar";
import ProposalSummary from "../../components/ProposalSummary/ProposalSummary";
import { useBookingApprovalListContext } from "../../hooks/contexts/BookingApprovalListContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import { useScrollToError } from "../../hooks/UseScrollToError";
import { useValidation } from "../../hooks/UseValidation";
import { determineApprovalStage } from "../../utils/ApprovalUtils";
import { baseString } from "../../utils/SchemaUtil";
import LimboPage from "../generals/LimboPage";
import api from "../../services/PabloApiService";
import { SolicitorResponse } from "../../common/Responses";

interface BookingApprovalForm {
  status?: string;
  solicitorId?: string;
  bookingRemarks: string;
}

const schema: SchemaOf<BookingApprovalForm> = object().shape({
  status: baseString().required().default("approve").label("Solictor"),
  solicitorId: baseString().when("status", {
    is: "approve",
    then: baseString().required().label("Solictor"),
  }),
  bookingRemarks: baseString().required().label("Remarks").max(500),
});

const BookingApprovalFormPage: React.FC = () => {
  const { proposalId } = useParams();
  const bookingApprovalList = useBookingApprovalListContext();
  const modal = useModalContext();
  const loadingPage = useLoadingPageContext();
  const navigate = useNavigate();

  const validation = useValidation<BookingApprovalForm>(schema);
  const { scrollOnError } = useScrollToError<BookingApprovalForm>();
  const bookingApprovalForm = validation.watch();

  const [proposal, setProposal] = useState<Proposal | undefined>(
    useLocation().state
  );
  const [solicitors, setSolicitors] = useState<Solicitor[]>([]);

  const latestApprovals = determineApprovalStage(
    proposal?.allApprovals,
    proposal?.stage
  );

  useEffect(() => {
    if (!proposal) showProposal();
    handleSolicitors();
  }, []);

  useEffect(() => {
    scrollOnError(validation.errors);
  }, [validation.errors]);

  const showProposal = async () => {
    loadingPage.start();

    const selectedProposal = await bookingApprovalList.show(
      parseInt(proposalId!)
    );

    setProposal(selectedProposal);

    loadingPage.end();
  };

  if (!proposal && !bookingApprovalList.isLoading) {
    return <LimboPage type="not-found" />;
  }

  if (!proposal) {
    return <></>;
  }

  const handleSolicitors = async () => {
    if (proposal.status === "pending-booking" && solicitors.length <= 0) {
      loadingPage.start();
      await getSolicitors();
      loadingPage.end();
    }
  };

  const getSolicitors = async (): Promise<boolean> => {
    const { data, success } = await api.get<SolicitorResponse>("/solicitors");

    if (success) {
      setSolicitors(data.data);
    }

    return true;
  };

  const approve = async (body: BookingApprovalForm) => {
    loadingPage.start();

    const success: boolean = await bookingApprovalList.update(
      "approve",
      proposal!.id,
      {
        solicitorId: body?.solicitorId && parseInt(body.solicitorId),
        remarks: body.bookingRemarks,
      }
    );

    if (success) {
      navigate(Path.bookingApprovals);
      toast.success(
        "Successfully booked a proposal. Some of the proposals with the same units have been cancelled."
      );
    }

    loadingPage.end();
  };

  const decline = async (body: BookingApprovalForm) => {
    loadingPage.start();

    const success: boolean = await bookingApprovalList.update(
      "decline",
      proposal!.id,
      {
        remarks: body.bookingRemarks,
      }
    );

    if (success) {
      navigate(Path.bookingApprovals);
      toast.success("Proposal declined.");
    }

    loadingPage.end();
  };

  const resubmit = async (body: BookingApprovalForm) => {
    loadingPage.start();

    const success: boolean = await bookingApprovalList.update(
      "resubmit",
      proposal.id,
      {
        remarks: body.bookingRemarks,
      }
    );

    if (success) {
      navigate(Path.bookingApprovals);
      toast.success("Requested proposal for resubmission.");
    }

    loadingPage.end();
  };

  const validate = async (type: ApprovalOption, callback: Function) => {
    validation.setValue("status", type);

    const result = await validation.trigger();

    if (result) callback();
  };

  return (
    <>
      {/* page */}
      <div className="col h-full justify-between">
        <div>
          <BackButton text="Back to CE approval listing" />

          <FormCard
            showRequired={false}
            title={
              proposal.status === "pending-booking" ? "Assign a Solicitor" : ""
            }
          >
            {proposal.status === "pending-booking" && solicitors.length > 0 && (
              <>
                <DropdownField
                  id="solicitorId"
                  label="Solicitor"
                  placeholder="Select a solicitor"
                  error={validation.errors.solicitorId?.message}
                  {...validation.register("solicitorId", { value: "" })}
                  items={solicitors.map((solicitor) => {
                    return {
                      text: solicitor.name,
                      value: solicitor.id.toString(),
                    };
                  })}
                />

                <Line />
              </>
            )}
            <h2 className="mb-4">Proposal Summary</h2>

            <ProposalSummary
              title=""
              summarized={false}
              units={
                [
                  {
                    buildup: proposal?.unit?.type?.buildUp || 0,
                    grossPrice: 0,
                    idTheme: proposal?.unit?.theme,
                    orientation: proposal?.unit?.type?.orientation,
                    pricePsf: 0,
                    status: "",
                    type: proposal?.unit?.type?.name.toString() as string,
                    unitNo: proposal?.unit?.number,
                    level: proposal?.unit?.level,
                  },
                ] as PEUnit[]
              }
              customers={proposal?.customers as Customer[]}
              companies={proposal?.companies as Company[]}
              opportunitySource={
                proposal?.opportunitySource as OpportunitySource
              }
              solicitor={proposal.solicitor}
              salesPicInfo={proposal.user}
              salesPackage={proposal?.salesPackage as SalesPackage}
              bookingInfo={proposal?.booking as Booking}
              remarks={proposal?.remarks}
              approvals={
                latestApprovals?.length
                  ? latestApprovals
                  : determineApprovalStage(proposal?.allApprovals, "creation")
              }
              soldInfo={proposal?.soldDetail}
              cancelledInfo={proposal?.cancelledDetail}
              withdrawnInfo={proposal?.withdrawnDetail}
            />

            {proposal.status === "pending-booking" && (
              <div>
                <Line className="mb-7 mt-3" />

                <h2 className="mb-4">Booking Remarks</h2>

                <div className="mt-6">
                  <Textarea
                    id="booking-approval-remarks"
                    rows={5}
                    label="Remarks"
                    error={validation.errors.bookingRemarks?.message}
                    required={true}
                    maxLength={500}
                    placeholder="Please input Receipt ID, Reference Number"
                    {...validation.register("bookingRemarks")}
                  />
                </div>
              </div>
            )}
          </FormCard>
        </div>

        {["pending-booking", "pending-cancellation"].includes(
          proposal.status
        ) && (
          <BottomBar>
            <button
              id="request-booking-resubmission-button"
              className="secondary-button mr-auto"
              onClick={() =>
                validate("resubmit", () =>
                  modal.confirmation({
                    title: `Are you sure you want to request for booking resubmission for this proposal?`,
                    onConfirm: {
                      text: "Confirm",
                      action: () => resubmit(bookingApprovalForm),
                    },
                  })
                )
              }
            >
              Request for Resubmission
            </button>

            <button
              id="decline-booking-button"
              className="secondary-button mr-4"
              onClick={() =>
                validate("decline", () =>
                  modal.confirmation({
                    title: `Are you sure you want to decline booking for this proposal?`,
                    onConfirm: {
                      text: "Confirm",
                      action: () => decline(bookingApprovalForm),
                    },
                  })
                )
              }
            >
              Decline
            </button>

            <button
              id="approve-booking-button"
              className="primary-button"
              onClick={() =>
                validate("approve", () =>
                  modal.confirmation({
                    title:
                      proposal?.status === "pending-booking"
                        ? "Are you sure you want to approve booking for this proposal?"
                        : "Are you sure you want to approve cancellation for this proposal?",
                    onConfirm: {
                      text: "Confirm",
                      action: () => approve(bookingApprovalForm),
                    },
                  })
                )
              }
            >
              Approve
            </button>
          </BottomBar>
        )}
      </div>
    </>
  );
};

export default BookingApprovalFormPage;
