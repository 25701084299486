/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { createRef, useEffect, useState } from "react";
import { Company } from "../../common/Types";
import { printAddress } from "../../utils/CommonUtils";
import Line from "../General/Line";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";

interface CompanyInfoSummaryProps {
  index: number;
  company: Company;
  viewImage: (blobPath: string | null) => void;
}

const CompanyInfoSummary: React.FC<CompanyInfoSummaryProps> = (props) => {
  const { company } = props;

  const ref = createRef<HTMLDivElement>();
  const [showMoreCompanyInfo, setShowMoreCompanyInfo] =
    useState<boolean>(false);

  const toggleShowMore = () => {
    setShowMoreCompanyInfo(!showMoreCompanyInfo);
  };

  useEffect(() => {
    const element = ref.current;

    if (element && showMoreCompanyInfo)
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }, [showMoreCompanyInfo]);

  return (
    <>
      <div
        ref={ref}
        className={classNames(
          "px-5 pt-3 transition-all duration-300 overflow-hidden gradient-bg-to-b from-section-background bg-section-background",
          {
            "max-h-[220px]": !showMoreCompanyInfo,
            "to-section-background max-h-[1500px]": showMoreCompanyInfo,
          }
        )}
      >
        <h4 className="uppercase mb-4">Company Information</h4>

        <table className="bg-transparent table-fixed w-full">
          <thead>
            <tr className="h-6 border-none">
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Company Name
              </th>
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Contact No.
              </th>
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Registration No.
              </th>
              <th className="min-w-[250px] desktop:w-[250px]" />
              <th className="min-w-[250px] desktop:w-[250px]" />
            </tr>
          </thead>

          <tbody>
            <tr className="h-6 border-none">
              <td className="text-sm font-bold px-0 pt-3 pb-6">
                {company?.name}
              </td>
              <td className="text-sm font-bold px-0 pt-3 pb-6">
                {`+${company?.phoneCode}${company?.phoneNumber}`}
              </td>
              <td className="text-sm font-bold px-0 pt-3 pb-6">
                {company?.registrationNumber}
              </td>
              <td />
              <td />
            </tr>
          </tbody>
        </table>

        <div
          className={classNames({
            "opacity-40": !showMoreCompanyInfo,
          })}
        >
          <table
            className={classNames("table-fixed w-full bg-transparent", {
              "opacity-30": !showMoreCompanyInfo,
            })}
          >
            <thead>
              <tr className="h-6 border-none">
                <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                  Address
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className="h-6 border-none">
                <td className="text-sm font-bold px-0">
                  {printAddress(company?.address)}
                </td>
              </tr>
            </tbody>
          </table>

          {(company?.attachments ?? []).length > 0 && (
            <>
              <div
                className={classNames(
                  "font-normal uppercase transition-all ease-in-out",
                  {
                    "opacity-10": !showMoreCompanyInfo,
                  }
                )}
              >
                <h4 className="uppercase mt-8 mb-4 text-sm">
                  Company Superform
                </h4>

                {company?.attachments?.map((attachment, index) => (
                  <div
                    key={`company-info-summary-image-${index}`}
                    className="row flex justify-between items-center h-[100px] my-3 text-xs "
                  >
                    <div className="row flex items-center">
                      <div className="border border-[#A9695B33] w-[100px] h-[100px]">
                        <PabloIcon
                          icon={Icon.myProposal}
                          className="gray-icon w-[50px] h-full m-auto object-contain"
                        />
                      </div>

                      <a
                        className="cursor-pointer pl-16"
                        href={attachment.path}
                        rel="noreferrer"
                        target="_blank"
                        download
                      >
                        <PabloIcon icon={Icon.download} className="gray-icon" />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {(company?.customers ?? []).length > 0 &&
            company?.customers?.map((personal, index) => (
              <div key={`company-personal-info-${index}`}>
                <Line className="mt-6 mb-4" />

                <h5 className="uppercase mb-4 text-sm">
                  Authorised Person Information {index + 1}
                </h5>

                <table className="table-fixed w-full bg-transparent">
                  <thead>
                    <tr className="h-6 border-none">
                      <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                        Salutation
                      </th>
                      <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                        Full Name
                      </th>
                      <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                        Contact No.
                      </th>
                      <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                        Email
                      </th>
                      <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                        Title
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr className="h-6 border-none">
                      <td className="text-sm font-bold p-0">
                        {personal?.salutation || "--"}
                      </td>
                      <td className="text-sm font-bold p-0">
                        {personal?.fullName || "--"}
                      </td>
                      <td className="text-sm font-bold p-0">
                        {`+${personal.phoneCode}${personal.phoneNumber}`}
                      </td>
                      <td className="text-sm font-bold p-0">
                        {personal?.email || "--"}
                      </td>
                      <td className="text-sm font-bold p-0">
                        {personal?.title || "--"}
                      </td>
                    </tr>

                    <tr
                      className={classNames("h-6 border-none", {
                        "opacity-40": !showMoreCompanyInfo,
                        "opacity-100": showMoreCompanyInfo,
                      })}
                    >
                      <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                        Identification No.
                      </th>
                      <th />
                      <th />
                      <th />
                      <th />
                    </tr>

                    <tr
                      className={classNames(
                        "h-6 border-none transition-all ease-in-out",
                        {
                          "opacity-10": !showMoreCompanyInfo,
                        }
                      )}
                    >
                      <td className="text-sm font-bold px-0 pt-3">
                        {personal?.identificationNumber || "--"}
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tbody>
                </table>

                <div className="font-normal uppercase transition-all ease-in-out pt-6">
                  <h4 className="uppercase mb-4 text-sm">Proof of Identity</h4>

                  {personal?.attachments?.map((attachment, attachmentIndex) => (
                    <div
                      key={`company-info-summary-image-${attachmentIndex}`}
                      className="row flex justify-between items-center h-[100px] my-3 text-xs "
                    >
                      <div className="row flex items-center">
                        <img
                          src={attachment.path}
                          onClick={() => props.viewImage(attachment.path)}
                          className="border border-[#A9695B33] object-contain w-[100px] h-[100px] cursor-pointer"
                          alt={`customer-${attachmentIndex}-attachments`}
                        />

                        <a
                          className="cursor-pointer pl-16"
                          href={attachment.path}
                          rel="noreferrer"
                          target="_blank"
                          download
                        >
                          <PabloIcon
                            icon={Icon.download}
                            className="gray-icon"
                          />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>

      <div
        className="text-xs text-center cursor-pointer bg-section-background mb-2"
        onClick={() => toggleShowMore()}
      >
        <div className="hover:opacity-40 transition duration-300 bg-section-background py-4">
          <span>{showMoreCompanyInfo ? "Show Less" : "Show More"}</span>
          <PabloIcon
            icon={Icon.collapseArrow}
            className={classNames(
              "w-[20px] h-[20px] black-icon mx-auto select-none",
              {
                "transform -rotate-90 transition-transform duration-500":
                  !showMoreCompanyInfo,
                "transform rotate-90 transition-transform duration-500":
                  showMoreCompanyInfo,
              }
            )}
          />
        </div>
      </div>
    </>
  );
};

export default CompanyInfoSummary;
