/* eslint-disable react-hooks/exhaustive-deps */
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import {
  towerACompletionDate,
  towerBCompletionDate,
} from "../../common/DateTime";
import { IFormSchema } from "../../common/Interfaces";
import { InstalmentFormDetails } from "../../common/Types";
import { useUnitListContext } from "../../hooks/contexts/UnitListContext";
import { Validation } from "../../hooks/UseValidation";
import { formatNumber, rounding } from "../../utils/CommonUtils";
import { dateDiff, now } from "../../utils/TimeDateUtils";
import DropdownField from "../Fields/DropdownField";
import TextField from "../Fields/TextField";
import Slider from "../General/Slider";
import InstalmentTable from "../Tables/InstalmentTable";

interface InstalmentPaymentStructureAccordianProps<T extends IFormSchema> {
  validation: Validation<T>;
  setValidated: Function;
  tower: string;
  nettSellingPrice: number;
  repaymentMonths: number;
  paymentStartDate: Moment;
  paymentEndDate: Moment;
  setRepaymentMonths: (months: number) => void;
  setPaymentStartDate: (startDate: Moment) => void;
  setPaymentEndDate: (endDate: Moment) => void;
  className?: string;
  defaultValues?: InstalmentFormDetails;
}

const InstalmentPaymentStructureAccordian: React.FC<
  InstalmentPaymentStructureAccordianProps<any>
> = (props) => {
  const {
    validation,
    setValidated,
    tower,
    nettSellingPrice,
    repaymentMonths,
    paymentStartDate,
    paymentEndDate,
    setRepaymentMonths,
    setPaymentStartDate,
    setPaymentEndDate,
    defaultValues,
  } = props;

  const MATCH_TOTAL_PRICE_ERROR = {
    type: "priceNotFulfilled",
    message: "Please rebalance the amount to match the nett selling price",
  };
  const hasDefaultValues = !!(
    defaultValues?.initialPayment ||
    defaultValues?.instalmentPayment ||
    defaultValues?.outstandingPayment
  );
  const unitList = useUnitListContext();
  const { unitPrices } = unitList;
  const paymentStructureFields = validation.watch(
    "paymentStructure.fixedInstalmentDetails"
  );
  const error = validation?.errors?.paymentStructure?.fixedInstalmentDetails;

  const nowUntilCompletionMonthsDiff = dateDiff(
    now.clone().endOf("month"),
    moment(
      tower === "A" ? towerACompletionDate : towerBCompletionDate,
      "MM/YYYY"
    ),
    "months"
  );

  const [remainingAmount, setRemainingAmount] = useState<number>(
    hasDefaultValues ? 0 : nettSellingPrice
  );
  const [maxInstalmentMonths, setMaxInstalmentMonths] = useState<number>(
    nowUntilCompletionMonthsDiff - 1
  );
  const [pricePerMonth, setPricePerMonth] = useState<number>(0);
  const [pricePerMonthRemainder, setPricePerMonthRemainder] =
    useState<number>(0);
  const [isFocused, setFocused] = useState<boolean>(false);
  const [isOnFirstLoad, setOnFirstLoad] = useState<boolean>(
    hasDefaultValues ? true : false
  );

  const paymentStartDateList =
    nowUntilCompletionMonthsDiff - 1 < 6
      ? nowUntilCompletionMonthsDiff - 1
      : nowUntilCompletionMonthsDiff - 1 < 0
      ? 0
      : 6;

  const handleRepaymentMonths = (numOfMonths: number) => {
    setOnFirstLoad(false);

    if (numOfMonths < 1 || !numOfMonths) {
      setRepaymentMonths(1);
      return;
    }

    if (numOfMonths > maxInstalmentMonths) {
      setRepaymentMonths(maxInstalmentMonths);
      return;
    }

    setRepaymentMonths(numOfMonths);
  };

  const calculateRemainingAmount = () => {
    const remainingPrice =
      (nettSellingPrice || 0) -
      ((paymentStructureFields?.initialPayment || 0) +
        (paymentStructureFields?.instalmentPayment || 0) +
        (paymentStructureFields?.outstandingPayment || 0));

    setRemainingAmount(remainingPrice);
  };

  useEffect(() => {
    const tempDate = paymentStartDate.clone().add(repaymentMonths, "month");

    setPaymentEndDate(tempDate);
    setPricePerMonth(
      rounding(
        (paymentStructureFields?.instalmentPayment || 0) / repaymentMonths,
        "floor"
      )
    );
    setPricePerMonthRemainder(
      (paymentStructureFields?.instalmentPayment || 0) % repaymentMonths
    );
  }, [
    nettSellingPrice,
    paymentStructureFields?.instalmentPayment,
    paymentStartDate,
    repaymentMonths,
  ]);

  useEffect(() => {
    const newMax = dateDiff(
      paymentStartDate.clone().startOf("month"),
      moment(
        tower === "A" ? towerACompletionDate : towerBCompletionDate,
        "MM/YYYY"
      ),
      "months"
    );

    setMaxInstalmentMonths(newMax);
    repaymentMonths > newMax && handleRepaymentMonths(newMax);
  }, [paymentStartDate]);

  useEffect(() => {
    if (!isOnFirstLoad) {
      validation.setValue(
        `paymentStructure.fixedInstalmentDetails.initialPayment`,
        null
      );
      validation.setValue(
        `paymentStructure.fixedInstalmentDetails.instalmentPayment`,
        null
      );
      validation.setValue(
        `paymentStructure.fixedInstalmentDetails.outstandingPayment`,
        null
      );
    }
  }, [nettSellingPrice]);

  useEffect(() => {
    calculateRemainingAmount();
  }, [
    nettSellingPrice,
    paymentStructureFields?.initialPayment,
    paymentStructureFields?.instalmentPayment,
    paymentStructureFields?.outstandingPayment,
  ]);

  useEffect(() => {
    const initialPaymentIsEqualToTotalPrice =
      paymentStructureFields?.initialPayment === nettSellingPrice;
    const isEqualToTotalPrice =
      paymentStructureFields?.initialPayment +
        paymentStructureFields?.instalmentPayment +
        paymentStructureFields?.outstandingPayment ===
      nettSellingPrice;
    const hasEqualToTotalPriceError =
      error?.instalmentPayment?.type === "priceNotFulfilled" ||
      error?.outstandingPayment?.type === "priceNotFulfilled";

    if (!initialPaymentIsEqualToTotalPrice && !isEqualToTotalPrice) {
      if (!hasEqualToTotalPriceError) {
        validation.setError(
          `paymentStructure.fixedInstalmentDetails.instalmentPayment`,
          MATCH_TOTAL_PRICE_ERROR
        );
        validation.setError(
          `paymentStructure.fixedInstalmentDetails.outstandingPayment`,
          MATCH_TOTAL_PRICE_ERROR
        );
      }
    } else {
      if (hasEqualToTotalPriceError) {
        validation.clearErrors(
          `paymentStructure.fixedInstalmentDetails.instalmentPayment`
        );
        validation.clearErrors(
          `paymentStructure.fixedInstalmentDetails.outstandingPayment`
        );
      }
    }

    if (validation.errors?.paymentStructure) {
      setValidated("error");
    } else {
      setValidated("warning");
    }
  }, [validation.watch()]);

  return (
    <>
      <div className="pt-5 px-5 bg-section-background col">
        <div className="row w-full justify-between items-stretch gap-5">
          <div className="col w-1/2 gap-5">
            <div className="row w-full bg-white p-5 h-[108px] gap-5">
              <div className="h-full w-1/2 bg-[#3077841A] uppercase py-3 pl-4">
                <div className="font-normal text-sm">Nett Selling Price</div>
                {unitPrices && (
                  <div className="font-bold text-lg">
                    RM {formatNumber(nettSellingPrice)}
                  </div>
                )}
              </div>
              <div className="h-full w-1/2 bg-[#8E8E3D26] uppercase py-3 pl-4">
                <div className="font-normal text-sm">Remaining Price</div>
                {unitPrices && (
                  <div className="font-bold text-lg">
                    {remainingAmount < 0
                      ? `< RM 0`
                      : `RM ${formatNumber(remainingAmount)}`}
                  </div>
                )}
              </div>
            </div>
            <div className="w-full bg-white">
              <div className="m-5">
                <TextField
                  id="instalment-amount"
                  label="Instalment Amount"
                  required={false}
                  dynamicWidth={true}
                  error={error?.instalmentPayment?.message}
                  frontLabel="RM"
                  onFocus={() => setFocused(true)}
                  defaultValue={defaultValues?.instalmentPayment}
                  {...validation.register(
                    `paymentStructure.fixedInstalmentDetails.instalmentPayment`,
                    {
                      onChange: () => setOnFirstLoad(false),
                      setValueAs: (v) =>
                        parseInt(
                          v ? v.toString().replace(/[^a-zA-Z0-9.]/g, "") : ""
                        ) || 0,
                      onBlur: () => setFocused(false),
                    }
                  )}
                  value={
                    isFocused
                      ? paymentStructureFields?.instalmentPayment
                      : formatNumber(paymentStructureFields?.instalmentPayment)
                  }
                />
              </div>
            </div>
            <div className="w-full bg-white h-full">
              <div className="m-5">
                <TextField
                  id="outstanding-amount"
                  label="Outstanding Amount"
                  required={false}
                  dynamicWidth={true}
                  error={error?.outstandingPayment?.message}
                  frontLabel="RM"
                  onFocus={() => setFocused(true)}
                  defaultValue={defaultValues?.outstandingPayment}
                  {...validation.register(
                    `paymentStructure.fixedInstalmentDetails.outstandingPayment`,
                    {
                      onChange: () => setOnFirstLoad(false),
                      setValueAs: (v) =>
                        parseInt(
                          v ? v.toString().replace(/[^a-zA-Z0-9.]/g, "") : ""
                        ) || 0,
                      onBlur: () => setFocused(false),
                    }
                  )}
                  value={
                    isFocused
                      ? paymentStructureFields?.outstandingPayment
                      : formatNumber(paymentStructureFields?.outstandingPayment)
                  }
                />
              </div>
            </div>
          </div>
          <div className="col w-1/2 gap-5">
            <div className="w-full bg-white">
              <div className="m-5">
                <TextField
                  id="initial-amount"
                  label="Initial Payment Upon SPA Signing"
                  required={true}
                  dynamicWidth={true}
                  error={error?.initialPayment?.message}
                  frontLabel="RM"
                  onFocus={() => setFocused(true)}
                  defaultValue={defaultValues?.initialPayment}
                  {...validation.register(
                    `paymentStructure.fixedInstalmentDetails.initialPayment`,
                    {
                      onChange: () => setOnFirstLoad(false),
                      setValueAs: (v) =>
                        parseInt(
                          v ? v.toString().replace(/[^a-zA-Z0-9.]/g, "") : ""
                        ) || 0,
                      onBlur: () => setFocused(false),
                    }
                  )}
                  value={
                    isFocused
                      ? paymentStructureFields?.initialPayment
                      : formatNumber(paymentStructureFields?.initialPayment)
                  }
                />
              </div>
            </div>
            <div className="w-full bg-white h-[108px]">
              <div className="m-5">
                <DropdownField
                  id="payment-start-date"
                  key="payment-start-date"
                  label="Payment Start Date"
                  error=""
                  items={[...Array(paymentStartDateList)]?.map(
                    (value, index) => {
                      const addingMonths = now
                        .clone()
                        .add(index + 1, "month")
                        .format("MM/YYYY");

                      return {
                        text: addingMonths,
                        value: addingMonths,
                      };
                    }
                  )}
                  onChange={(e) => {
                    setPaymentStartDate(
                      moment(e.currentTarget.value, "MM/YYYY")
                    );
                  }}
                  defaultValue={
                    now?.clone().add(1, "month").format("MM/YYYY") || ""
                  }
                  dynamicWidth={true}
                />
              </div>
            </div>
            <div className="w-full bg-white h-full">
              <div className="p-5">
                <div className="w-full">
                  <label className="uppercase">
                    Total Months for Repayment
                  </label>
                </div>
                <div className="w-full pt-5 flex items-center">
                  <Slider
                    id="repayment-months"
                    value={repaymentMonths}
                    min={1}
                    max={maxInstalmentMonths}
                    hasField={true}
                    handleChange={handleRepaymentMonths}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InstalmentTable
        instalmentPayment={
          defaultValues?.instalmentPayment ||
          paymentStructureFields?.instalmentPayment ||
          0
        }
        paymentStartDate={paymentStartDate}
        paymentEndDate={paymentEndDate}
        pricePerMonth={pricePerMonth}
        pricePerMonthRemainder={pricePerMonthRemainder}
        editable={true}
      />
    </>
  );
};

export default InstalmentPaymentStructureAccordian;
