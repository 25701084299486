import React from "react";
import Country from "../common/Country";
import {
  Address,
  Country as CountryType,
  DropdownItem,
  RoundingOption,
  SortingOption,
} from "../common/Types";
import { camelizeKeys } from "humps";

const modifyNumberThreeA = (number: string, isUnit: boolean = false) => {
  const lastTwo = number.slice(-2);

  if (lastTwo === "3A") {
    return isUnit ? number.slice(0, -2) + "04" : number.slice(0, -2) + "4";
  }

  return number;
};

export const determineUnitLevel = (unitNo: string) => {
  const sliced = unitNo.split("-");
  return modifyNumberThreeA(sliced[1]);
};

export const modifyNumberFour = (number: string, isUnit: boolean = false) => {
  const lastNumber = isUnit ? number.slice(-2) : number.slice(-1);

  if (lastNumber === "4" || lastNumber === "04") {
    return isUnit ? number.slice(0, -2) + "3A" : number.slice(0, -1) + "3A";
  }

  return number;
};

export const formatNumber = (num: string | number) => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const forceDecimal = (num: number, decimalPoints: number = 2) => {
  return parseFloat(num.toFixed(decimalPoints));
};

export const rearrangePEApiUnits = (obj: any) => {
  let tempArr: any = [];

  Object.keys(obj).forEach((key) => {
    let tempObj = camelizeKeys({ unitNo: key, ...obj[key] });
    tempArr.push(tempObj);
  });

  return tempArr;
};

export const rearrangeJsonData = (obj: any) => {
  const { data } = obj;
  let tempArr: any = [];
  let currentLevel: string = "";

  data
    .sort(function (a: any, b: any) {
      let aParts = a.unitNumber.split("-");
      let bParts = b.unitNumber.split("-");

      for (let i = 1; i <= 2; i++) {
        if (aParts[i] !== bParts[i]) {
          let tempAParts = aParts[i] === "3A" ? "04" : aParts[i];
          let tempBParts = bParts[i] === "3A" ? "04" : bParts[i];

          return tempAParts.localeCompare(tempBParts);
        }
      }

      let aLastPart = aParts[2];
      let bLastPart = bParts[2];

      let aIsNumber = !isNaN(aLastPart);
      let bIsNumber = !isNaN(bLastPart);

      if (aIsNumber && bIsNumber) {
        return parseInt(aLastPart) - parseInt(bLastPart);
      } else if (!aIsNumber && !bIsNumber) {
        return aLastPart.localeCompare(bLastPart);
      } else if (aIsNumber) {
        if (aLastPart === "03" && bLastPart === "3A") {
          return -1;
        } else if (aLastPart === "3A" && bLastPart === "04") {
          return -1;
        } else {
          return 1;
        }
      } else {
        return -1;
      }
    })
    .forEach((unit: any, index: number) => {
      let tempLevel: string = determineUnitLevel(unit.unitNumber);

      if (currentLevel !== tempLevel) {
        tempArr[tempLevel] = [
          {
            projectId: unit.section.project.id,
            sectionId: unit.section.id,
            unitId: unit.id,
            buildup: unit.unitType.buildup,
            grossPrice: unit.grossPrice,
            idTheme: unit.unitTheme.name,
            orientation: unit.orientation,
            pricePsf: calculatePsfValue(
              unit.grossPrice,
              unit.unitType.buildup,
              "round"
            ),
            status: unit.unitStatus,
            type: unit.unitType.name,
            unitNo: unit.unitNumber,
            level: unit.floor,
          },
        ];
      } else {
        tempArr[tempLevel].push({
          projectId: unit.section.project.id,
          sectionId: unit.section.id,
          unitId: unit.id,
          buildup: unit.unitType.buildup,
          grossPrice: unit.grossPrice,
          idTheme: unit.unitTheme.name,
          orientation: unit.orientation,
          pricePsf: calculatePsfValue(
            unit.grossPrice,
            unit.unitType.buildup,
            "round"
          ),
          status: unit.unitStatus,
          type: unit.unitType.name,
          unitNo: unit.unitNumber,
          level: unit.floor,
        });
      }

      currentLevel = tempLevel;
    });

  return tempArr;
};

export const capitalize = (text: string, firstLetterOnly: boolean = false) => {
  return firstLetterOnly
    ? text.charAt(0).toUpperCase() + text.slice(1)
    : text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const camelToText = (text: string) => {
  // turn camel case to sentence case
  var result = text.replace(/([A-Z])/g, " $1").trimStart();
  // capitalize first letter
  return capitalize(result);
};

export const formatErrorMessage = (message?: string) => {
  if (!message)
    return "Missing error message. Please inform Pablo team about this.";

  let sentence = capitalize(message.trimStart(), true);

  const regex = /([A-Z](?![A-Z]))(?=\S)/g;

  if (regex.test(sentence)) {
    sentence = camelToText(sentence);
  }

  return sentence.charAt(sentence.length - 1) === "."
    ? sentence
    : `${sentence}.`;
};

export const freezeCaret = (e: React.FormEvent<HTMLInputElement>) => {
  const caret = e.currentTarget.selectionStart;
  const element = e.currentTarget;

  window.requestAnimationFrame(() => {
    element.selectionStart = caret;
    element.selectionEnd = caret;
  });
};

export const rounding = (value: number, option?: RoundingOption): number => {
  return option === "round"
    ? Math.round(value)
    : option === "floor"
    ? Math.floor(value)
    : option === "ceil"
    ? Math.ceil(value)
    : value;
};

export const calculateRebatePercentage = (
  grossSellingPrice: number,
  nettSellingPrice: number,
  option?: RoundingOption
): number => {
  let rebate = forceDecimal(
    ((grossSellingPrice - nettSellingPrice) / grossSellingPrice) * 100
  );

  return rounding(rebate, option);
};

export const calculateRebateValue = (
  grossSellingPrice: number,
  nettSellingPrice: number,
  option?: RoundingOption
): number => {
  let rebate = forceDecimal(grossSellingPrice - nettSellingPrice);

  return rounding(rebate, option);
};

export const calculatePsfValue = (
  price: number,
  buildUp?: number,
  option?: RoundingOption
): number => {
  return rounding(price / (buildUp || 0), option);
};

export const range = (start: number, stop: number, step: number): number[] => {
  if (typeof stop == "undefined") {
    // one param defined
    stop = start;
    start = 0;
  }

  if (typeof step == "undefined") {
    step = 1;
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return [];
  }

  var result = [];

  for (var i = start; step > 0 ? i <= stop : i >= stop; i += step) {
    let itrNum = i;

    if (itrNum % 1 !== 0) itrNum = parseFloat(itrNum.toFixed(1));

    result.push(itrNum);
  }

  return result;
};

export const listDropdownItems = (item: string) => {
  return {
    text: capitalize(item),
    value: item,
  };
};

export const listDropdownPhoneNumbers = (country: CountryType) => {
  return {
    text: `+${country.dialCode} - ${country.name}`,
    value: country.dialCode,
  };
};

export const printAddress = (address?: Address): string => {
  let addressLineTwo = address?.line2 != null ? `${address?.line2} ` : "";

  return `
    ${address?.line1},
    ${addressLineTwo}
    ${address?.postcode}, ${address?.city},${" "}
    ${address?.state},${" "}
    ${Country.countryName(address!.country)}
  `;
};

export const sortDropdown = (
  listItems: DropdownItem[],
  sortOption?: SortingOption
): DropdownItem[] => {
  let sortedItems = [...listItems];

  if (sortOption) {
    sortedItems.sort((a, b) => {
      let aValue = a.text.toLowerCase();
      let bValue = b.text.toLowerCase();

      if (aValue < bValue) {
        return sortOption === "asc" ? -1 : 1;
      } else if (aValue > bValue) {
        return sortOption === "asc" ? 1 : -1;
      }

      return 0;
    });
  }

  return sortedItems;
};
