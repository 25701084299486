/* eslint-disable react-hooks/exhaustive-deps */
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Path from "../../common/Path";
import {
  defaultStages,
  PaymentStructureType,
  reverseStructurableType,
  structurableType,
} from "../../common/PaymentStructure";
import { packageableType } from "../../common/SalesPackage";
import { FormErrors, Hook, PEUnit, Rebate } from "../../common/Types";
import CustomerBasicInfoAccordian from "../../components/Accordion/CustomerBasicInfoAccordian";
import PaymentStructureAccordian from "../../components/Accordion/PaymentStructureAccordian";
import SalesPackageAccordian from "../../components/Accordion/SalesPackageAccordian";
import BackButton from "../../components/Buttons/BackButton";
import { GroupExpandableCard } from "../../components/Cards/ExpandableCard";
import SectionCard from "../../components/Cards/SectionCard";
import Textarea from "../../components/General/Textarea";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import BottomBar from "../../components/Navs/BottomBar";
import OpportunitySourceSummary from "../../components/Shared/OpportunitySourceSummary";
import ProposalSummary from "../../components/ProposalSummary/ProposalSummary";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import { useProposalListContext } from "../../hooks/contexts/ProposalListContext";
import { useArray } from "../../hooks/UseArray";
import { useScrollToError } from "../../hooks/UseScrollToError";
import { useUnload } from "../../hooks/UseUnload";
import { useValidation } from "../../hooks/UseValidation";
import {
  EditProposalForm,
  editProposalSchema,
} from "../../schemas/ProposalSchema";
import {
  calculateRebatePercentage,
  calculateRebateValue,
} from "../../utils/CommonUtils";
import { now } from "../../utils/TimeDateUtils";

const EditProposalFormPage: React.FC = (props) => {
  const { proposalId } = useParams();
  const navigate = useNavigate();
  const validation = useValidation<EditProposalForm>(editProposalSchema);
  const { scrollOnError } = useScrollToError<EditProposalForm>();
  const proposalList = useProposalListContext();
  const loadingPage = useLoadingPageContext();
  const modal = useModalContext();
  const { state } = useLocation();
  const tower: string = state.unit.number.charAt(0);
  const { setDirty } = useUnload();
  const units: PEUnit[] =
    ([
      {
        buildup: state.unit.type.buildUp,
        grossPrice: 0,
        idTheme: state.unit.theme,
        orientation: state.unit.type.orientation,
        pricePsf: 0,
        status: state.unit.status,
        type: state.unit.type.name,
        unitNo: state.unit.number,
        level: state.unit.level,
      },
    ] as PEUnit[]) ?? [];
  const proposal = validation.watch();
  const defaultRebate = calculateRebatePercentage(
    state.salesPackage.grossSellingPrice,
    state.salesPackage.nettSellingPrice
  );

  /** General States */
  const [formErrors, setFormErrors] = useState<FormErrors>({
    scheduleH: "",
  });
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [hasPaymentStructureOpened, setHasPaymentStructureOpened] =
    useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  /** Payment Structure States */
  const [paymentStructureType, setPaymentStructureType] =
    useState<PaymentStructureType>(
      structurableType(state.salesPackage.structurableType)
    );

  /** Instalment Payment Structure Accordian States */
  const [repaymentMonths, setRepaymentMonths] = useState<number>(
    state.salesPackage?.structurable?.totalRepaymentMonths || 1
  );
  const [paymentStartDate, setPaymentStartDate] = useState<Moment>(
    state.salesPackage?.structurable?.paymentStartDate
      ? moment(state.salesPackage?.structurable?.paymentStartDate)
      : now.clone().add(1, "month")
  );
  const [paymentEndDate, setPaymentEndDate] = useState<Moment>(
    paymentStartDate.clone().add(repaymentMonths, "month")
  );

  /** Schedule H Payment Structure Accordian States */
  const [progressRebates, setProgressRebates] = useState<Rebate[]>(
    defaultStages.map((stage: Rebate) => {
      let result = state.salesPackage?.structurable?.rebates?.filter(
        (rebateStage: Rebate) => rebateStage.stage === stage.stage
      );

      if (result && result.length > 0)
        return {
          stage: stage.stage,
          value: result[0].value,
          stringValue: result[0].value.toString(),
        };

      return {
        stage: stage.stage,
        value: stage.value,
        stringValue: stage.stringValue,
      };
    })
  );
  const [remainingRebate, setRemainingRebate] = useState<number>(0);

  /** Flexible Instalment States */
  const [detailsIndexes, dispatchDetailsIndexes] = useArray<number>(
    state?.salesPackage?.structurable?.details?.length
      ? state?.salesPackage?.structurable?.details?.length > 0
        ? [...state.salesPackage.structurable.details.keys()]
        : [0, 1, 2]
      : [0, 1, 2]
  );

  const onPaymentStructureTypeChange = (selectedType: PaymentStructureType) => {
    setPaymentStructureType(selectedType);
    validation.setValue(`paymentStructure.type`, selectedType);
  };

  const handleCustomValidation = () => {
    let errorCount = 0;
    let errors = formErrors;

    if (paymentStructureType === "schedule-h" && remainingRebate > 0) {
      errors.scheduleH = "Please apply all the remaining rebate";
      errorCount++;
    } else if (paymentStructureType === "schedule-h" && remainingRebate < 0) {
      errors.scheduleH =
        "Rebate has exceeded the maximum rebate amount selected";
      errorCount++;
    } else {
      errors.scheduleH = "";
    }

    setFormErrors(errors);
    return errorCount > 0 ? true : false;
  };

  const onPreview = async () => {
    setHasPaymentStructureOpened(true);
    const hasErrors = handleCustomValidation();
    const result = await validation.trigger();

    if (result && Object.keys(validation.errors).length === 0 && !hasErrors) {
      setDirty(false);

      modal.view({
        title: "",
        containerClassName: "w-10/12 h-[90%]",
        modalClassName: "w-full px-5 pt-5",
        content: (
          <>
            <ProposalSummary
              title="Proposal review"
              summarized={true}
              units={units}
              customers={[
                {
                  id: 0,
                  ...proposal?.customerBasicInfo,
                  phoneCode: parseInt(proposal?.customerBasicInfo?.phoneCode),
                },
              ]}
              opportunitySource={{
                id: 0,
                ...state.opportunitySource,
                commissionFixedFee: state.opportunitySource.commissionRate,
                updatedCommissionFixedFee:
                  state.opportunitySource.updatedCommissionRate,
                phoneCode: parseInt(state.opportunitySource.phoneCode),
              }}
              salesPackage={{
                id: 0,
                recommendedNettSellingPrice:
                  state.salesPackage.recommendedNettSellingPrice,
                recommendedMaxRebate: state.salesPackage.recommendedMaxRebate,
                grossSellingPrice: state.salesPackage.grossSellingPrice,
                packageableType: proposal?.salesPackage?.selectedSalesPackage,
                structurableType: reverseStructurableType(paymentStructureType),
                structurable: {
                  id: 0,
                  initialPayment:
                    proposal?.paymentStructure?.fixedInstalmentDetails
                      ?.initialPayment || 0,
                  instalmentPayment:
                    proposal?.paymentStructure?.fixedInstalmentDetails
                      ?.instalmentPayment || 0,
                  outstandingPayment:
                    proposal?.paymentStructure?.fixedInstalmentDetails
                      ?.outstandingPayment || 0,
                  totalRepaymentMonths: repaymentMonths,
                  paymentStartDate: paymentStartDate,
                  rebates: progressRebates,
                  details:
                    proposal?.paymentStructure?.flexibleInstalmentDetails || [],
                },
                ...proposal?.salesPackage,
              }}
              remarks={proposal?.remarks}
            />

            <BottomBar innerClassName="pr-0">
              <button
                id="submit-proposal-button"
                className="primary-button"
                onClick={() => {
                  setShowConfirmation(true);
                }}
              >
                Submit proposal
              </button>
            </BottomBar>
          </>
        ),
      });
    }
  };

  const submitForm = async () => {
    let success = false;
    loadingPage.start();

    let paymentStructureBody =
      paymentStructureType === "instalment"
        ? {
            initialPayment:
              proposal?.paymentStructure?.fixedInstalmentDetails
                ?.initialPayment || 0,
            instalmentPayment:
              proposal?.paymentStructure?.fixedInstalmentDetails
                ?.instalmentPayment || 0,
            outstandingPayment:
              proposal?.paymentStructure?.fixedInstalmentDetails
                ?.outstandingPayment || 0,
            paymentStartDate: paymentStartDate
              .clone()
              .startOf("month")
              .format("YYYY-MM-DD"),
            totalRepaymentMonths: repaymentMonths,
          }
        : paymentStructureType === "flexible-instalment"
        ? {
            details: proposal?.paymentStructure?.flexibleInstalmentDetails
              ?.filter((detail) => detail)
              ?.map((detail) => {
                return {
                  item: detail.item,
                  datePayable: detail?.datePayable || "",
                  amountPayable: detail.amountPayable,
                  remarks: detail?.remarks || "",
                };
              }),
          }
        : {
            rebates: progressRebates
              .filter((progress) => progress.value > 0)
              .map((progress) => {
                return { stage: progress.stage, value: progress.value };
              }),
          };

    const proposalBody = {
      remarks: proposal?.remarks,
      customer: proposal?.customerBasicInfo,
      salesPackage: {
        paymentMethod: proposal?.salesPackage?.paymentMethod,
        nettSellingPrice: proposal?.salesPackage?.nettSellingPrice,
        rebateType: proposal?.salesPackage?.rebateType,
        hooks: proposal?.salesPackage?.hooks
          ?.filter((hook: Hook) => hook?.item?.trim() && hook?.value)
          .map((hook: Hook) => {
            return { item: hook?.item?.trim(), value: hook?.value };
          }),
        remarks: proposal?.salesPackage?.remarks,
        paymentStructure: {
          type: paymentStructureType,
          ...paymentStructureBody,
        },
      },
    };

    success = await proposalList.update(parseInt(proposalId!), proposalBody);

    loadingPage.end();
    setShowSuccess(success);
  };

  useEffect(() => {
    validation.setValue(`salesPackage.rebate`, defaultRebate);
    validation.setValue(
      `salesPackage.nettSellingPrice`,
      state.salesPackage.nettSellingPrice
    );
    validation.setValue(`paymentStructure.type`, paymentStructureType);
  }, []);

  useEffect(() => {
    setDirty(true);
  }, [proposal]);

  useEffect(() => {
    scrollOnError(validation.errors);
  }, [validation.errors]);

  return (
    <>
      {/* page */}
      <div className="col min-h-screen h-max justify-between">
        <div className="mb-20">
          <BackButton text="Back to my proposal" />

          <div className="h-7">
            <h2 className="capitalize mx-7">Edit Proposal Form</h2>
          </div>

          <OpportunitySourceSummary
            opportunitySource={state.opportunitySource}
            className="col shadow rounded-sm mx-7 my-4 relative bg-white px-8 py-5"
            titleClassName="capitalize text-lg font-semibold"
          />

          <GroupExpandableCard
            defaultValue="sales-packages"
            onExpand={(accordian) =>
              accordian === "payment-structure"
                ? setHasPaymentStructureOpened(true)
                : {}
            }
          >
            <SalesPackageAccordian
              validation={validation}
              hasSalesPackageOpened={true}
              units={units}
              defaultValues={{
                rebate: defaultRebate,
                nettSellingPrice: state.salesPackage.nettSellingPrice,
                selectedSalesPackage: packageableType(
                  state.salesPackage.packageableType
                ),
                grrValues: {
                  rate: state.salesPackage.packageable?.rate?.toString(),
                  year: state.salesPackage.packageable?.numberOfYears?.toString(),
                },
                paymentMethod: state.salesPackage.paymentMethod,
                grossSellingPrice: state.salesPackage.grossSellingPrice,
                recommendedMaxRebate: {
                  maxRecDisc: state.salesPackage.recommendedMaxRebate,
                  recAbsNettPrice:
                    state.salesPackage.recommendedNettSellingPrice,
                },
                rebateType: state.salesPackage.rebateType,
                hooks: state.salesPackage.hooks,
                remarks: state.salesPackage.remarks,
              }}
            />

            <PaymentStructureAccordian
              validation={validation}
              type={paymentStructureType}
              onTypeChange={onPaymentStructureTypeChange}
              formErrors={formErrors}
              tower={tower}
              nettSellingPrice={
                proposal?.salesPackage?.nettSellingPrice ||
                state.salesPackage.nettSellingPrice
              }
              repaymentMonths={repaymentMonths}
              paymentStartDate={paymentStartDate}
              paymentEndDate={paymentEndDate}
              hasPaymentStructureOpened={hasPaymentStructureOpened}
              setFormErrors={setFormErrors}
              setRepaymentMonths={setRepaymentMonths}
              setPaymentStartDate={setPaymentStartDate}
              setPaymentEndDate={setPaymentEndDate}
              totalRebate={
                proposal?.salesPackage?.rebateType === "percentage"
                  ? proposal?.salesPackage?.rebate
                  : calculateRebateValue(
                      state.salesPackage.grossSellingPrice,
                      proposal?.salesPackage?.nettSellingPrice ||
                        state.salesPackage.nettSellingPrice
                    )
              }
              remainingRebate={remainingRebate}
              progressRebates={progressRebates}
              setProgressRebates={setProgressRebates}
              setRemainingRebate={setRemainingRebate}
              hasDefaultValues={true}
              grossSellingPrice={state.salesPackage.grossSellingPrice}
              rebateType={proposal?.salesPackage?.rebateType}
              defaultPaymentStructure={state.salesPackage.structurable}
              detailsIndexes={detailsIndexes}
              dispatchDetailsIndexes={dispatchDetailsIndexes}
            />

            <CustomerBasicInfoAccordian
              validation={validation}
              defaultValues={state.customers[0]}
            />
          </GroupExpandableCard>

          <SectionCard
            title="Remarks (Optional)"
            className="col shadow rounded-sm mx-7 my-4 relative bg-white px-8 py-5"
            titleClassName="capitalize text-lg font-semibold"
          >
            <Textarea
              id="proposal-general-remarks"
              rows={5}
              error={validation.errors.remarks?.message}
              required={false}
              maxLength={500}
              defaultValue={state?.remarks || ""}
              {...validation.register("remarks")}
            />
          </SectionCard>
        </div>

        <BottomBar>
          <button
            id="save-as-draft-button"
            className="secondary-button mr-auto group cursor-not-allowed opacity-50"
          >
            Save as draft
          </button>

          <button
            id="preview-proposal-button"
            className="primary-button"
            onClick={() => {
              onPreview();
            }}
          >
            Preview
          </button>
        </BottomBar>
      </div>

      <ConfirmationModal
        show={showConfirmation}
        dismissible={false}
        onHide={() => {
          /* Not hideable */
        }}
        type="warning"
        title="Are you sure you want to resubmit this proposal?"
        onConfirm={{
          text: "Submit",
          action: submitForm,
        }}
        onCancel={{
          text: "Cancel",
          action: () => setShowConfirmation(false),
        }}
      />

      <ConfirmationModal
        show={showSuccess}
        dismissible={false}
        onHide={() => setShowSuccess(false)}
        type="success"
        title="You have successfully resubmitted this proposal."
        onConfirm={{
          text: "My Proposals",
          action: () => {
            modal.setShow(false);
            navigate(Path.myProposals);
          },
        }}
        onCancel={{
          text: "Back to main page",
          action: () => {
            modal.setShow(false);
            navigate(Path.main);
          },
        }}
      />
    </>
  );
};

export default EditProposalFormPage;
