class Path {
  // main page
  static readonly main = "/units";

  // calculator page
  static readonly calculator = "/calculator";

  // auth
  static readonly login = "/login";
  static readonly forgotPassword = "/forgot-password";
  static readonly changePassword = "/change-password";

  // units
  static readonly units = "/units";

  // booking approvals
  static readonly bookingApprovals = "/booking-approvals";
  static readonly bookingApprovalDetails = (proposalId: any) =>
    `/booking-approvals/${proposalId}`;

  // convert to sold
  static readonly sold = (proposalId: any) =>
    `/booking-approvals/sold/${proposalId}`;

  // management approvals
  static readonly managementApprovals = "/management-approvals";
  static readonly managementApprovalDetails = (proposalId: any) =>
    `/management-approvals/${proposalId}`;

  // proposals
  static readonly proposals = "/proposals";
  static readonly newProposals = "/units/proposal/new";
  static readonly viewProposal = (proposalId: any) =>
    `/proposals/${proposalId}`;

  // my proposals
  static readonly myProposals = "/my-proposals";
  static readonly viewMyProposal = (proposalId: any) =>
    `/my-proposals/${proposalId}`;
  static readonly editProposal = (proposalId: any) =>
    `/my-proposals/${proposalId}/edit`;

  // users
  static readonly users = "/users";
  static readonly newUser = "/users/new";
  static readonly editUser = (userId: any) => `/users/${userId}/edit`;

  // agents
  static readonly agents = "/agents";
  static readonly newAgent = "/agents/new";
  static readonly editAgent = (agentId: any) => `/agents/${agentId}/edit`;

  // agencies
  static readonly agencies = "/agencies";
  static readonly newAgency = "/agencies/new";
  static readonly editAgency = (agencyId: any) => `/agencies/${agencyId}/edit`;

  // booking
  static readonly newBooking = "/my-proposals/book";
  static readonly editBooking = (proposalId: any) =>
    `/my-proposals/book/${proposalId}/edit`;

  // generate 7 letters
  static readonly viewGenerateLetters = (
    proposalId: any,
    isCE: boolean = false
  ) => `/generate-letters/${proposalId}/view${isCE ? "/ce" : ""}`;

  static readonly editGenerateLetters = (proposalId: any) =>
    `/generate-letters/${proposalId}/edit`;
}

export default Path;
