import React, { forwardRef, useState } from "react";
import classNames from "classnames";
import { formatErrorMessage } from "../../utils/CommonUtils";

interface TextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  rows: number;
  label?: string;
  required?: boolean;
  error?: string;
  hasError?: boolean;
  innerClassName?: string;
  containerClassName?: string;
  dynamicWidth?: boolean;
  frontLabel?: string;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      rows,
      containerClassName,
      innerClassName,
      dynamicWidth,
      hasError,
      error,
      frontLabel,
      label,
      required,
      ...otherProps
    } = props;

    const [characterCount, setCharacterCount] = useState<number>(
      otherProps?.value?.toString()?.length || 0
    );

    return (
      <div
        className={classNames(
          "col",
          {
            "w-full tablet:w-[498px]": !dynamicWidth,
          },
          containerClassName
        )}
      >
        <label
          className={classNames(
            "textarea-border w-full h-full",
            {
              "border-primary": hasError || error,
              "relative box-border": frontLabel,
            },
            innerClassName
          )}
        >
          {label && (
            <div className="row gap-1">
              <label className="uppercase block mb-1">{label}</label>
              {(required ?? true) && <span className="text-dark-red">*</span>}
            </div>
          )}

          <textarea
            ref={ref}
            autoComplete="off"
            rows={rows}
            className={classNames(
              "w-full font-semibold focus:outline-none resize-none",
              {
                "pl-6": frontLabel,
              }
            )}
            onInput={(e) => setCharacterCount(e.currentTarget.value.length)}
            {...otherProps}
          />

          {frontLabel && (
            <div
              className={classNames(
                "absolute left-5 text-sm font-bold box-border",
                {
                  "bottom-7": rows === 2,
                  "bottom-12": rows === 3,
                  "bottom-[4.25rem]": rows === 4,
                }
              )}
            >
              {frontLabel}
            </div>
          )}
        </label>

        <div className="row justify-between">
          <div className="w-2/3 font-light text-sm text-primary pt-2 transition-all duration-200">
            {error ? formatErrorMessage(error) : " "}
          </div>

          <div className="w-1/3 text-right text-xs text-gray italic opacity-60 mt-2">
            {characterCount} / {otherProps.maxLength} characters
          </div>
        </div>
      </div>
    );
  }
);

export default Textarea;
