import React from "react";
import { User } from "../../common/Types";

interface AvatarProps {
  user?: User | null;
  size?: "small" | "medium" | "large";
}

const Avatar: React.FC<AvatarProps> = ({ user, size = "small" }) => {
  const diameter = size === "small" ? "w-8 h-8" : size === "medium" ? "w-12 h-12" : "w-16 h-16";
  const className = `rounded-full object-cover ${diameter}`;

  if (!user?.avatarUrl) {
    return (
      <div className={`${className} bg-[#B111164D] text-white flex justify-center items-center uppercase`}>
        {user?.fullName[0] || "?"}
      </div>
    );
  }

  return <img className={className} src={user.avatarUrl} alt="Avatar" />;
};

export default Avatar;
