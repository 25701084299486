/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Path from "../../common/Path";
import { Proposal } from "../../common/Types";
import SquareButton from "../../components/Buttons/SquareButton";
import BaseCard from "../../components/Cards/BaseCard";
import Icon from "../../components/Icons/Icon";
import SortCaret from "../../components/Tables/SortCaret";
import TabBarTable from "../../components/Tables/TabBarTable";
import HeaderText from "../../components/Texts/HeaderText";
import { useManagementApprovalListContext } from "../../hooks/contexts/ManagementApprovalListContext";
import { useLoadingBarContext } from "../../hooks/contexts/LoadingBarContext";
import { useFilter } from "../../hooks/Filter";
import {
  approvalCategoryName,
  MAX_NUMBER_OF_APPROVALS,
} from "../../common/Approval";
import TableHeader from "../../components/Tables/TableHeader";
import {
  calculateNumberOfApprovals,
  determineApprovalStage,
} from "../../utils/ApprovalUtils";

const ManagementApprovalListPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const approvalList = useManagementApprovalListContext();
  const loadingBar = useLoadingBarContext();
  const { items, sort } = useFilter<Proposal>(approvalList.proposals);
  const approvedProposals = items.filter((item) =>
    ["approved", "booked", "pending-booking"].includes(item.status)
  );
  const pendingProposals = items.filter((item) =>
    ["pending-approval", "pending-cancellation"].includes(item.status)
  );
  const declinedProposals = items.filter((item) =>
    ["declined-approval", "cancelled"].includes(item.status)
  );

  useEffect(() => {
    approvalList.index();
  }, [location]);

  useEffect(() => {
    if (approvalList.isLoading) loadingBar.start();
    else loadingBar.end();
  }, [approvalList.isLoading]);

  const viewManagementApproval = (proposal: Proposal) => {
    navigate(Path.managementApprovalDetails(proposal.id), { state: proposal });
  };

  return (
    <>
      {/* page */}
      <div className="col">
        <HeaderText title="Management Approval Listing" />

        <BaseCard>
          <TabBarTable
            tabs={["Pending Approval", "Approved", "Cancelled/Declined"]}
            isLoading={approvalList.isLoading}
            onSearching={approvalList.search}
            searchHint="Search by buyer name or unit"
            headers={[
              <SortCaret
                key="buyer-name"
                header="Buyer name"
                onToggle={() => sort("customers[0].fullName,companies[0].name")}
              />,
              <SortCaret
                key="created-by"
                header="Created by"
                onToggle={() => sort("user.fullName")}
              />,
              <SortCaret
                key="unit"
                header="Unit"
                onToggle={() => sort("unit.number")}
              />,
              <TableHeader key="number-of-approvals" header="Approvals" />,
              <TableHeader key="required-approvals" header="Req. Approvals" />,
            ]}
            bodies={[
              pendingProposals,
              approvedProposals,
              declinedProposals,
            ].map((filters) => {
              return filters.map((proposal, index) => {
                let latestApprovals = determineApprovalStage(
                  proposal?.allApprovals,
                  proposal?.stage
                );

                return (
                  <tr
                    key={`proposal-${proposal.id}-${index}`}
                    className={classNames("h-0 align-top", {
                      "bg-opacity-50 bg-ink-well": proposal.status === "sold",
                    })}
                  >
                    <td
                      className={classNames("py-3", {
                        "opacity-50": proposal.status === "sold",
                      })}
                    >
                      {proposal.customers?.length
                        ? proposal.customers?.map((customer, customerIndex) => (
                            <div key={`customer-${customerIndex}`}>
                              {customer.fullName}
                            </div>
                          ))
                        : proposal.companies?.map((company, companyIndex) => (
                            <div key={`customer-${companyIndex}`}>
                              {company.name}
                            </div>
                          ))}
                    </td>
                    <td
                      className={classNames("py-3", {
                        "opacity-50": proposal.status === "sold",
                      })}
                    >
                      {proposal.user?.fullName}
                    </td>
                    <td
                      className={classNames("py-3", {
                        "opacity-50": proposal.status === "sold",
                      })}
                    >
                      {proposal?.unit?.number}
                    </td>
                    <td
                      className={classNames("py-3", {
                        "opacity-50": proposal.status === "sold",
                      })}
                    >
                      {latestApprovals &&
                        `${calculateNumberOfApprovals(
                          latestApprovals
                        )} / ${MAX_NUMBER_OF_APPROVALS}`}
                    </td>
                    <td
                      className={classNames("py-3", {
                        "opacity-50": proposal.status === "sold",
                      })}
                    >
                      {latestApprovals &&
                        latestApprovals[
                          latestApprovals.length - 1
                        ]?.categories?.map((category, categoryIndex) => {
                          return (
                            <div key={`approval-category-${categoryIndex}`}>
                              {approvalCategoryName(category)}
                            </div>
                          );
                        })}
                    </td>
                    <td className="flex justify-end items-center h-11 pl-0">
                      {proposal.status === "sold" ? (
                        <SquareButton
                          onclick={() => {}}
                          icon={Icon.proposalListing}
                        />
                      ) : (
                        <SquareButton
                          onclick={() => viewManagementApproval(proposal)}
                          icon={Icon.eye}
                        />
                      )}
                    </td>
                  </tr>
                );
              });
            })}
          />
        </BaseCard>
      </div>
    </>
  );
};

export default ManagementApprovalListPage;
