import classNames from "classnames";
import React, { ReactElement } from "react";
import Modal from "./Modal";

interface ConfirmationModalAction {
  text: string;
  buttonClassName?: string;
  action?: React.MouseEventHandler;
}

interface ConfirmationModalProps {
  show: boolean;
  title: string;
  description?: string;
  dismissible?: boolean;
  onHide: React.MouseEventHandler;
  type?: "success" | "warning" | "error";
  onConfirm: ConfirmationModalAction;
  onSecondaryAction?: ConfirmationModalAction;
  onCancel?: ConfirmationModalAction;
  content?: ReactElement;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
  return (
    <Modal
      show={props.show}
      type={props.type}
      onHide={props.onHide}
      dismissible={props.dismissible}
    >
      <div className="col justify-around items-center h-full">
        <h2 className="self-center text-center">{props.title}</h2>

        {props.description && (
          <div className="pt-4 pb-10 text-justify">{props.description}</div>
        )}

        {props.content}

        <div
          className={classNames({
            "mt-9": props?.onSecondaryAction,
          })}
        >
          <button
            onClick={(e) => {
              if (props.onConfirm.action) props.onConfirm.action(e);
              props.onHide(e);
            }}
            className={classNames(
              "primary-button mb-2 self-center h-[48px] min-w-[218px]",
              props.onConfirm?.buttonClassName
            )}
          >
            {props.onConfirm.text}
          </button>

          {props?.onSecondaryAction && (
            <button
              onClick={(e) => {
                if (props.onSecondaryAction?.action)
                  props.onSecondaryAction.action(e);
                props.onHide(e);
              }}
              className={classNames(
                "primary-button mb-2 self-center h-[48px] min-w-[218px]",
                props.onSecondaryAction?.buttonClassName
              )}
            >
              {props.onSecondaryAction.text}
            </button>
          )}

          {props?.onCancel && (
            <button
              onClick={props.onCancel?.action || props.onHide}
              className={classNames(
                "secondary-button mb-2 self-center h-[48px] min-w-[218px]",
                props.onCancel?.buttonClassName
              )}
            >
              {props.onCancel?.text || "Cancel"}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
