import React, { useState } from "react";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";

interface SearchFieldProps {
  hint: string;
  onChange: (value: string) => void;
}

const SearchField: React.FC<SearchFieldProps> = (props) => {
  const [value, setValue] = useState<string>("");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    props.onChange(e.target.value);
  };

  const clearValue = () => {
    setValue("");
    props.onChange("");
  };

  return (
    <label className="bg-white border-[1px] border-[#1F1F1F4D] w-[428px] h-10 px-5 items-center gap-2 row justify-between cursor-text">
      <input
        id="search-field"
        type="text"
        placeholder={props.hint}
        className="focus:outline-none w-full"
        onChange={onChange}
        value={value}
      />

      {value.length <= 0 ? (
        <PabloIcon icon={Icon.search} />
      ) : (
        <PabloIcon
          icon={Icon.close}
          onClick={clearValue}
          className="cursor-pointer"
        />
      )}
    </label>
  );
};

export default SearchField;
