/* eslint-disable react-hooks/exhaustive-deps */
import moment, { isMoment, Moment } from "moment";
import { Source, sourceName } from "../../common/OpportunitySource";
import {
  paymentStructureTypeName,
  StructurableType,
  structurableType,
} from "../../common/PaymentStructure";
import {
  PackageableType,
  packageableType,
  salesPackageName,
  SalesPackageType,
} from "../../common/SalesPackage";
import {
  Approval,
  Booking,
  CancelledDetail,
  Company,
  Customer,
  OpportunitySource,
  PEUnit,
  Rebate,
  SalesPackage,
  SoldDetail,
  Solicitor,
  User,
  WithdrawnDetail,
} from "../../common/Types";
import InstalmentTable from "../Tables/InstalmentTable";
import ScheduleHTable from "../Tables/ScheduleHTable";
import {
  calculateRebatePercentage,
  calculateRebateValue,
  capitalize,
  formatNumber,
  printAddress,
  rounding,
} from "../../utils/CommonUtils";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import FlexibleInstalmentTableDisplay from "../Tables/FlexibleInstalmentTableDisplay";
import ManagementApprovalHistory from "../Shared/ManagementApprovalHistory";
import CustomerInfoSummary from "./CustomerInfoSummary";
import CompanyInfoSummary from "./CompanyInfoSummary";
import SoldInfoSummary from "./SoldInfoSummary";
import BookingInfoSummary from "./BookingInfoSummary";
import CancelledInfoSummary from "./CancelledInfoSummary";

interface ProposalSummaryProps {
  title: string;
  units: PEUnit[];
  customers?: Customer[];
  companies?: Company[];
  opportunitySource: OpportunitySource;
  salesPackage: SalesPackage;
  bookingInfo?: Booking;
  salesPicInfo?: User;
  solicitor?: Solicitor;
  remarks?: string;
  summarized: boolean;
  approvals?: Approval[];
  soldInfo?: SoldDetail;
  cancelledInfo?: CancelledDetail;
  withdrawnInfo?: WithdrawnDetail;
}

const ProposalSummary: React.FC<ProposalSummaryProps> = (props) => {
  const {
    title,
    units,
    customers,
    companies,
    opportunitySource,
    salesPackage,
    bookingInfo,
    salesPicInfo,
    summarized,
    solicitor,
    remarks,
    approvals,
    soldInfo,
    cancelledInfo,
    withdrawnInfo,
  } = props;

  const modal = useModalContext();

  const viewImage = (blobPath: string | null) => {
    modal.view({
      title: "",
      containerClassName: "w-6/12 max-h-[90%]",
      modalClassName: "w-full px-5 py-5 overflow-y-auto",
      content: (
        <img
          src={blobPath!}
          alt="uploaded-file"
          className="border border-[#A9695B33]"
        />
      ),
    });
  };

  const hooks = salesPackage?.hooks
    ?.filter((hook) => hook?.item?.trim() && hook?.value)
    .map((hook) => {
      return { item: hook?.item?.trim(), value: hook?.value };
    });

  const totalCommissionAmount = rounding(
    (salesPackage?.nettSellingPrice || 0) *
      ((opportunitySource?.updatedCommissionRate || 0) / 100),
    "round"
  );

  const rebate =
    salesPackage?.rebateType === "percentage"
      ? salesPackage?.rebate ??
        calculateRebatePercentage(
          salesPackage?.grossSellingPrice,
          salesPackage?.nettSellingPrice
        )
      : calculateRebateValue(
          salesPackage?.grossSellingPrice,
          salesPackage?.nettSellingPrice
        );

  const paymentStartDate = isMoment(
    salesPackage?.structurable?.paymentStartDate
  )
    ? salesPackage?.structurable?.paymentStartDate
    : moment(salesPackage?.structurable?.paymentStartDate);

  const paymentEndDate = paymentStartDate
    ?.clone()
    .add(salesPackage?.structurable?.totalRepaymentMonths, "month");

  return (
    <>
      <div className="overflow-y-auto pb-2">
        {title && (
          <div className="text-center">
            <h2 className="capitalize pb-5">{title}</h2>
          </div>
        )}

        {solicitor && (
          <div className="px-5 py-3 mb-2 bg-section-background">
            <h4 className="uppercase mb-4">Solicitor Assigned</h4>
            <table className="bg-transparent table-fixed w-full">
              <thead>
                <tr className="h-6 border-none">
                  <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                    Full Name
                  </th>
                  <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                    PIC Name
                  </th>
                  <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                    Phone Number
                  </th>
                  <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                    Email
                  </th>
                  <th className=" p-0 min-w-[250px] desktop:w-[250px]" />
                </tr>
              </thead>

              <tbody>
                <tr className="h-6 border-none">
                  <td className="text-sm font-bold p-0">{solicitor.name}</td>
                  <td className="text-sm font-bold p-0">{solicitor.picName}</td>
                  <td className="text-sm font-bold p-0">
                    {`+${solicitor.phoneCode}${solicitor.phoneNumber}`}
                  </td>
                  <td className="text-sm font-bold p-0">{solicitor.email}</td>
                  <td />
                </tr>
              </tbody>
            </table>

            <table className="table-fixed w-full bg-transparent mt-4">
              <thead>
                <tr className="h-6 border-none">
                  <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                    Address
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr className="h-6 border-none">
                  <td className="text-sm font-bold px-0 pb-6">
                    {printAddress(solicitor.address)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {(customers ?? []).length > 0 &&
          customers?.map((customer, index) => (
            <div key={`customer-info-summary-${index}`}>
              <CustomerInfoSummary
                index={index}
                customer={customer}
                summarized={summarized}
                viewImage={viewImage}
              />
            </div>
          ))}

        {(companies ?? []).length > 0 &&
          companies?.map((company, index) => (
            <div key={`company-info-summary-${index}`}>
              <CompanyInfoSummary
                index={index}
                company={company}
                viewImage={viewImage}
              />
            </div>
          ))}

        <div className="px-5 py-3 mb-2 bg-section-background">
          <h4 className="uppercase mb-4">Unit Details</h4>
          <table className="bg-transparent table-fixed w-full">
            <thead>
              <tr className="h-6 border-none">
                <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                  Unit no.
                </th>
                <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                  Unit type
                </th>
                <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                  Orientation
                </th>
                <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                  Build up
                </th>
                <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                  ID theme
                </th>
              </tr>
            </thead>

            <tbody>
              {units?.map((unit, index) => {
                return (
                  <tr key={`unit-no-${index}`} className="h-6 border-none">
                    <td className="text-sm font-bold p-0">{unit.unitNo}</td>
                    <td className="text-sm font-bold p-0">{unit.type}</td>
                    <td className="text-sm font-bold p-0">
                      {unit.orientation}
                    </td>
                    <td className="text-sm font-bold p-0">
                      {formatNumber(unit.buildup)}
                    </td>
                    <td className="text-sm font-bold p-0">
                      {capitalize(unit.idTheme)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="px-5 py-3 mb-2 bg-section-background">
          <h4 className="uppercase mb-4">Sales Package Summary</h4>
          <table className="bg-transparent table-fixed w-full">
            <thead>
              <tr className="h-6 border-none">
                <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                  Channel
                </th>
                <th className="p-0 min-w-[250px] desktop:w-[250px]" />
                <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                  {opportunitySource?.type === "agent"
                    ? "Agency"
                    : opportunitySource?.type === "digital"
                    ? "Name"
                    : opportunitySource?.type === "kora"
                    ? "Referrer"
                    : opportunitySource?.type === "bgb" ||
                      opportunitySource?.type === "existing-buyer"
                    ? "Unit No."
                    : opportunitySource?.type === "business-associate"
                    ? "Company / Individual Name"
                    : opportunitySource?.type === "brand-collaboration"
                    ? "Event Name"
                    : "Source"}
                </th>
                <th className="p-0 min-w-[250px] desktop:w-[250px]" />
                <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                  {opportunitySource?.type === "agent" && "Agent"}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className="h-6 border-none">
                <td className="text-sm font-bold p-0">
                  {sourceName(opportunitySource?.type as Source)}
                </td>
                <td className="text-sm font-bold p-0" />
                <td className="text-sm font-bold p-0">
                  {opportunitySource?.type === "kora" ||
                  opportunitySource?.type === "digital"
                    ? opportunitySource?.fullName
                    : opportunitySource?.title || `--`}
                </td>
                <td className="text-sm font-bold p-0" />
                <td className="text-sm font-bold p-0">
                  {opportunitySource?.type === "agent" &&
                    (opportunitySource?.fullName || `--`)}
                </td>
              </tr>

              <tr>
                <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                  Commission
                </th>
                <th className="min-w-[250px] desktop:w-[250px]" />
                <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                  Commission Amount
                </th>
                <th className="min-w-[250px] desktop:w-[250px]" />
                <th className="min-w-[250px] desktop:w-[250px]" />
              </tr>

              <tr className="h-6 border-none">
                <td className="text-sm font-bold px-0 pt-3">
                  {`
                    ${opportunitySource?.type === "digital" ? "RM" : ""}
                    ${
                      opportunitySource?.type === "digital"
                        ? formatNumber(
                            opportunitySource?.updatedCommissionFixedFee || 0
                          )
                        : formatNumber(
                            opportunitySource?.updatedCommissionRate || 0
                          )
                    }${opportunitySource?.type !== "digital" ? "%" : ""}
                  `}
                </td>
                <td />
                <td className="text-sm font-bold px-0 pt-3">
                  RM{" "}
                  {opportunitySource?.type === "digital"
                    ? formatNumber(
                        opportunitySource?.updatedCommissionFixedFee || 0
                      )
                    : formatNumber(totalCommissionAmount)}
                </td>
                <td />
                <td />
              </tr>

              <tr>
                <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                  Sales Package
                </th>
                <th className="min-w-[250px] desktop:w-[250px]" />
                <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                  Rebate
                </th>
                <th className="min-w-[250px] desktop:w-[250px]" />
                <th className="min-w-[250px] desktop:w-[250px]" />
              </tr>

              <tr className="h-6 border-none">
                <td className="text-sm font-bold px-0 pt-3">
                  {salesPackageName(
                    packageableType(
                      salesPackage?.packageableType as PackageableType
                    ) ?? (salesPackage?.packageableType as SalesPackageType)
                  )}
                </td>
                <td />
                <td className="text-sm font-bold px-0 pt-3">
                  {salesPackage?.rebateType === "percentage"
                    ? `${rebate}%`
                    : `RM ${formatNumber(
                        calculateRebateValue(
                          salesPackage?.grossSellingPrice,
                          salesPackage?.nettSellingPrice
                        )
                      )}`}
                </td>
                <td />
                <td />
              </tr>

              <tr>
                <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                  Unit Gross Selling Price
                </th>
                <th className="min-w-[250px] desktop:w-[250px]" />
                <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                  Nett Selling Price
                </th>
                <th className="min-w-[250px] desktop:w-[250px]" />
                <th className="min-w-[250px] desktop:w-[250px]" />
              </tr>

              <tr className="h-6 border-none">
                <td className="text-sm font-bold px-0 pt-3">
                  RM {formatNumber(salesPackage?.grossSellingPrice)}
                </td>
                <td />
                <td className="text-sm font-bold px-0 pt-3">
                  RM {formatNumber(salesPackage?.nettSellingPrice)}
                </td>
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        </div>

        {(hooks?.length || salesPackage?.remarks) && (
          <div className="px-5 py-3 mb-2 bg-section-background">
            <h4 className="uppercase mb-4">
              Complimentary Package and Remarks
            </h4>

            <table className="bg-transparent table-fixed w-full">
              <thead>
                <tr className="h-6 border-none">
                  <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                    Item
                  </th>
                  <th className="min-w-[250px] desktop:w-[250px]" />
                  <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                    Value
                  </th>
                  <th className="min-w-[250px] desktop:w-[250px]" />
                  <th className="min-w-[250px] desktop:w-[250px]" />
                </tr>
              </thead>

              <tbody>
                {hooks?.length ? (
                  hooks.map((hook, index) => {
                    return (
                      <tr key={`unit-no-${index}`} className="h-6 border-none">
                        <td className="text-sm font-bold p-0">
                          {capitalize(hook?.item ?? "", true)}
                        </td>
                        <td />
                        <td className="text-sm font-bold p-0">
                          RM {hook?.value && formatNumber(hook.value)}
                        </td>
                        <td />
                        <td />
                      </tr>
                    );
                  })
                ) : (
                  <tr className="h-6 border-none">
                    <td className="text-sm font-bold p-0">-</td>
                    <td />
                    <td className="text-sm font-bold p-0">-</td>
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </table>

            <div className="row flex justify-start items-center gap-11 mt-6">
              <div className="w-full">
                <p className="text-sm uppercase mb-1">Remarks</p>
                <span className="text-sm font-bold">
                  {salesPackage.remarks || "--"}
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="px-5 pt-3 bg-section-background mb-2">
          <h4 className="uppercase">
            Payment Schedule{" "}
            {`(${paymentStructureTypeName(
              structurableType(
                salesPackage?.structurableType as StructurableType
              )
            )})`}
          </h4>
          <div className="w-full">
            {structurableType(
              salesPackage?.structurableType as StructurableType
            ) === "instalment" ? (
              <InstalmentTable
                initialPayment={
                  salesPackage?.structurable?.initialPayment as number
                }
                instalmentPayment={
                  salesPackage?.structurable?.instalmentPayment as number
                }
                outstandingPayment={
                  salesPackage?.structurable?.outstandingPayment as number
                }
                paymentStartDate={paymentStartDate as Moment}
                paymentEndDate={paymentEndDate as Moment}
                pricePerMonth={rounding(
                  (salesPackage?.structurable?.instalmentPayment as number) /
                    (salesPackage?.structurable
                      ?.totalRepaymentMonths as number),
                  "floor"
                )}
                pricePerMonthRemainder={
                  (salesPackage?.structurable?.instalmentPayment as number) %
                  (salesPackage?.structurable?.totalRepaymentMonths as number)
                }
                isModal={true}
                editable={false}
              />
            ) : structurableType(
                salesPackage?.structurableType as StructurableType
              ) === "flexible-instalment" ? (
              <FlexibleInstalmentTableDisplay
                grossSellingPrice={salesPackage?.grossSellingPrice}
                details={salesPackage?.structurable?.details}
              />
            ) : (
              <ScheduleHTable
                totalPrice={salesPackage?.grossSellingPrice}
                nettSellingPrice={salesPackage?.nettSellingPrice}
                totalRebate={rebate}
                progressRebates={
                  salesPackage?.structurable?.rebates as Rebate[]
                }
                editable={false}
                isModal={true}
                setProgressRebates={() => {}}
                setRemainingRebate={() => {}}
                rebateType={salesPackage?.rebateType}
              />
            )}
          </div>
        </div>

        {salesPicInfo && (
          <>
            <div className="px-5 py-3 mb-2 bg-section-background">
              <h4 className="uppercase mb-4">Sales PIC Details</h4>
              <table className="table-fixed w-full bg-transparent">
                <thead>
                  <tr className="h-6 border-none">
                    <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                      Name
                    </th>
                    <th className="min-w-[250px] desktop:w-[250px]" />
                    <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                      Email
                    </th>
                    <th className="min-w-[250px] desktop:w-[250px]" />
                    <th className="min-w-[250px] desktop:w-[250px]" />
                  </tr>
                </thead>

                <tbody>
                  <tr className="h-6 border-none">
                    <td className="text-sm font-bold px-0">
                      {salesPicInfo?.fullName}
                    </td>
                    <td />
                    <td className="text-sm font-bold px-0">
                      {salesPicInfo?.email}
                    </td>
                    <td />
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}

        {remarks && (
          <div className="px-5 py-3 mb-2 bg-section-background">
            <h4 className="uppercase mb-4">Proposal Remarks</h4>

            <div className="w-full bg-transparent mt-4">
              <div className="text-sm font-bold px-0 pb-3">{remarks}</div>
            </div>
          </div>
        )}

        {bookingInfo && (
          <BookingInfoSummary booking={bookingInfo} viewImage={viewImage} />
        )}

        {approvals && (approvals?.some((approval) => approval?.status) ?? []) && (
          <div className="px-5 py-3 mb-2 bg-section-background">
            <h4 className="uppercase mb-4">Management Approval Information</h4>

            <table className="table-auto w-full bg-transparent">
              <tbody key={`approval-status`}>
                <ManagementApprovalHistory approvals={approvals} />
              </tbody>
            </table>
          </div>
        )}

        {soldInfo && (
          <SoldInfoSummary soldDetail={soldInfo} viewImage={viewImage} />
        )}

        {cancelledInfo && (
          <CancelledInfoSummary cancelledDetail={cancelledInfo} />
        )}

        {withdrawnInfo?.reason && (
          <div className="px-5 py-3 mb-2 bg-section-background">
            <h4 className="uppercase mb-4">Reason of Withdrawal</h4>

            <div className="w-full bg-transparent mt-4">
              <div className="text-sm font-bold px-0 pb-3">
                {withdrawnInfo.reason}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProposalSummary;
