/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { IFormSchema } from "../../common/Interfaces";
import {
  paymentMethods,
  paymentMethodTypeName,
  RebateType,
  rebateTypeName,
  rebateTypes,
  salesPackageName,
  salesPackages,
} from "../../common/SalesPackage";
import { Hook, PEUnit, SalesPackageDefaultValues } from "../../common/Types";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useUnitListContext } from "../../hooks/contexts/UnitListContext";
import { Validation } from "../../hooks/UseValidation";
import { calculatePsfValue, formatNumber } from "../../utils/CommonUtils";
import {
  ExpandableCard,
  ExpandableCardValidated,
} from "../Cards/ExpandableCard";
import GrrCard from "../Cards/GrrCard";
import HooksFormCard from "../Cards/HooksFormCard";
import NettSellingPriceRebateCard from "../Cards/NettSellingPriceRebateCard";
import PercentageRebateCard from "../Cards/PercentageRebateCard";
import DropdownField from "../Fields/DropdownField";
import RadioField from "../Fields/RadioField";
import { RadioGroup } from "../General/Radio";
import Textarea from "../General/Textarea";
import ErrorText from "../Texts/ErrorText";

interface SalesPackageAccordianProps<T extends IFormSchema> {
  validation: Validation<T>;
  tower?: string;
  units?: PEUnit[];
  commission?: number;
  defaultValues?: SalesPackageDefaultValues;
  hasSalesPackageOpened?: boolean;
  className?: string;
}

const SalesPackageAccordian: React.FC<SalesPackageAccordianProps<any>> = (
  props
) => {
  const unitList = useUnitListContext();
  const loadingPage = useLoadingPageContext();

  const {
    validation,
    tower,
    units,
    commission,
    hasSalesPackageOpened,
    defaultValues,
    className,
  } = props;
  const { unitPrices, maxRecRebate, loadingItems, getMaxRecRebate } = unitList;
  const totalGrossPrice =
    defaultValues?.grossSellingPrice ?? unitPrices?.totalGrossPrice;
  const recommendedMaxRebate =
    defaultValues?.recommendedMaxRebate ?? maxRecRebate;
  const unitNumbers = units?.map((i) => i.unitNo);
  const buildUp = units?.[0]?.buildup || 0;

  const register = validation.register;
  const error = validation.errors.salesPackage;
  const salesPackage = validation.watch("salesPackage");

  const isGrr = salesPackage?.selectedSalesPackage === "grr";
  const isPercentage = salesPackage?.rebateType === "percentage";
  const isNettSellingPrice = salesPackage?.rebateType === "nett-selling-price";

  const [validated, setValidated] = useState<ExpandableCardValidated>("empty");
  const [isOnFirstLoad, setOnFirstLoad] = useState<boolean>(!!defaultValues);
  const [nettPsfPrice, setNettPsfPrice] = useState<number>(
    calculatePsfValue(salesPackage?.nettSellingPrice, buildUp, "round")
  );

  const onChangeNettSellingPrice = (price: number) => {
    if (isPercentage) {
      validation.clearErrors("salesPackage.nettSellingPrice");
      validation.setValue(`salesPackage.nettSellingPrice`, price);
      return;
    }

    const processedSellingPrice =
      price < 0 || !price
        ? 0
        : price > totalGrossPrice
        ? totalGrossPrice
        : price;

    if (processedSellingPrice <= 0) {
      validation.setError(`salesPackage.nettSellingPrice`, {
        type: "min",
        message: "Nett selling price cannot be 0",
      });
    } else if (salesPackage?.rebate > 50) {
      validation.setError(`salesPackage.nettSellingPrice`, {
        type: "nettSellingPriceExceeded",
        message: "Nett selling price cannot be 50% lower than the gross price",
      });
    } else {
      validation.clearErrors([
        "salesPackage.nettSellingPrice",
        "salesPackage.rebate",
      ]);
    }

    validation.setValue(`salesPackage.nettSellingPrice`, processedSellingPrice);
  };

  const onRadioChanged = (value: string) => {
    setOnFirstLoad(false);
    validation.setValue(`salesPackage.rebateType`, value as RebateType);
  };

  useEffect(() => {
    validation.setValue(
      "salesPackage.selectedSalesPackage",
      defaultValues?.selectedSalesPackage || "higher_rebate"
    );

    validation.setValue("salesPackage.grrValues", {
      rate: defaultValues?.grrValues?.rate || 6,
      year: defaultValues?.grrValues?.year || 2,
    });

    validation.setValue(
      "salesPackage.rebateType",
      defaultValues?.rebateType || "percentage"
    );
  }, []);

  useEffect(() => {
    if (loadingItems.length !== 0) loadingPage.start();
    else loadingPage.end();
  }, [loadingItems]);

  useEffect(() => {
    if (
      !defaultValues?.recommendedMaxRebate &&
      salesPackage?.selectedSalesPackage
    ) {
      const grr = isGrr
        ? {
            grr_rate: salesPackage?.grrValues?.rate,
            grr_years: salesPackage?.grrValues?.year,
          }
        : {};

      getMaxRecRebate({
        rec_disc_info: {
          tower: tower,
          units: unitNumbers,
          commission: commission,
          sales: {
            package: salesPackage?.selectedSalesPackage,
            rebate: salesPackage?.rebate,
            abs_rebate: salesPackage?.nettSellingPrice,
            ...grr,
          },
        },
      });
    }
  }, [salesPackage?.selectedSalesPackage, commission]);

  useEffect(() => {
    if (!isOnFirstLoad) {
      validation.setValue(`salesPackage.rebate`, 0);
      isPercentage && onChangeNettSellingPrice(totalGrossPrice);
      isNettSellingPrice && onChangeNettSellingPrice(0);
    }
  }, [salesPackage?.rebateType]);

  useEffect(() => {
    if (
      !defaultValues?.recommendedMaxRebate &&
      isGrr &&
      salesPackage?.grrValues
    ) {
      getMaxRecRebate({
        rec_disc_info: {
          tower: tower,
          units: unitNumbers,
          commission: commission,
          sales: {
            package: "grr",
            rebate: salesPackage?.rebate,
            abs_rebate: salesPackage?.nettSellingPrice,
            grr_rate: salesPackage?.grrValues?.rate,
            grr_years: salesPackage?.grrValues?.year,
          },
        },
      });
    }
  }, [salesPackage?.grrValues?.rate, salesPackage?.grrValues?.year]);

  useEffect(() => {
    setNettPsfPrice(
      calculatePsfValue(salesPackage?.nettSellingPrice, buildUp, "round")
    );
  }, [salesPackage?.nettSellingPrice]);

  useEffect(() => {
    if (hasSalesPackageOpened) {
      if (
        error ||
        (isNettSellingPrice && salesPackage?.nettSellingPrice <= 0)
      ) {
        setValidated("error");
      } else {
        (isPercentage &&
          salesPackage?.rebate > recommendedMaxRebate?.maxRecDisc) ||
        (isNettSellingPrice &&
          salesPackage?.nettSellingPrice <
            recommendedMaxRebate?.recAbsNettPrice) ||
        (isGrr &&
          salesPackage?.grrValues?.rate &&
          salesPackage?.grrValues?.year &&
          salesPackage?.grrValues?.rate * salesPackage?.grrValues?.year > 12) ||
        salesPackage?.hooks?.some((hook: Hook) => hook?.item || hook?.value)
          ? setValidated("warning")
          : setValidated("success");
      }
    }
  }, [
    validation.watch(),
    isGrr,
    hasSalesPackageOpened,
    recommendedMaxRebate,
    error,
  ]);

  return (
    <>
      <ExpandableCard
        value="sales-packages"
        title="Sales package"
        validated={validated}
        className={className}
        showRequired
      >
        <div className="row">
          <div className="w-full tablet:w-[360px] min-h-[80px] bg-[#3077841A] p-4 tablet:mr-3 uppercase">
            <div className="font-normal text-sm tracking-[.2px]">
              Unit Gross Selling Price
            </div>

            {unitPrices && (
              <div className="font-bold text-xl mt-2.5">
                RM {formatNumber(totalGrossPrice?.toString())}
              </div>
            )}
          </div>
        </div>

        <div className="mt-6">
          <h4 className="font-bold mb-4">Payment Method</h4>
          <div className="col gap-5">
            <div className="row -mb-1 gap-4">
              <DropdownField
                id="payment-method"
                key="payment-method"
                label="Select Payment Method"
                error={error?.paymentMethod?.message}
                items={paymentMethods.map((method) => {
                  return {
                    text: paymentMethodTypeName(method),
                    value: method,
                    description:
                      method === "ksk-credit"
                        ? "Please send a copy of your working file/excel sheet to CE team."
                        : "",
                  };
                })}
                defaultValue={defaultValues?.paymentMethod || paymentMethods[0]}
                {...register("salesPackage.paymentMethod")}
              />
              <DropdownField
                id="sales-package"
                key="sales-package"
                label="Select Sales Package"
                disabled={!!defaultValues}
                items={salesPackages.map((pkg) => {
                  return {
                    text: salesPackageName(pkg),
                    value: pkg,
                  };
                })}
                defaultValue={
                  defaultValues?.selectedSalesPackage || salesPackages[0]
                }
                {...register("salesPackage.selectedSalesPackage")}
              />
            </div>
          </div>
        </div>

        <div className="my-6">
          <h4 className="font-bold mb-4">Rebate Type</h4>
          <label className="uppercase">Select Rebate Type</label>
          <div className="flex-wrap flex items-center justify-start mt-2">
            <RadioGroup
              onChange={(type) => onRadioChanged(type as string)}
              defaultValue={defaultValues?.rebateType || rebateTypes[0]}
            >
              {rebateTypes.map((rebateType) => (
                <RadioField
                  id={`${rebateType}-radio-button`}
                  key={rebateType}
                  label={rebateTypeName(rebateType)}
                  value={rebateType}
                  innerClassName="pl-0 pr-4"
                />
              ))}
            </RadioGroup>
          </div>
        </div>

        <div className="row w-full flex flex-wrap justify-start gap-4">
          {/* Percentage rebate slider */}
          {isPercentage && (
            <PercentageRebateCard
              validation={validation}
              nettSellingPrice={salesPackage?.nettSellingPrice || 0}
              nettPsfPrice={nettPsfPrice}
              recommendedRebate={recommendedMaxRebate?.maxRecDisc}
              totalGrossPrice={totalGrossPrice}
              selectedSalesPackage={salesPackage?.selectedSalesPackage}
              onChangeNettSellingPrice={onChangeNettSellingPrice}
            />
          )}

          {/* Nett selling price absolute value */}
          {isNettSellingPrice && (
            <NettSellingPriceRebateCard
              validation={validation}
              sellingPrice={salesPackage?.nettSellingPrice || 0}
              psfPrice={nettPsfPrice}
              recommendedSellingPrice={recommendedMaxRebate?.recAbsNettPrice}
              totalGrossPrice={totalGrossPrice}
              selectedSalesPackage={salesPackage?.selectedSalesPackage}
              onChangeNettSellingPrice={onChangeNettSellingPrice}
            />
          )}

          {/** Options for GRR sales package */}
          {isGrr && (
            <GrrCard
              validation={validation}
              defaultValues={defaultValues?.grrValues}
            />
          )}
        </div>

        <div className="col w-full tablet:w-[498px]">
          {isPercentage &&
            (salesPackage?.rebate > recommendedMaxRebate?.maxRecDisc ? (
              <ErrorText
                text="You have exceeded the maximum allowed rebate, proposal will be submitted
        to management for approval."
                className="mt-3"
              />
            ) : isGrr &&
              salesPackage?.grrValues?.rate &&
              salesPackage?.grrValues?.year &&
              salesPackage?.grrValues.rate * salesPackage?.grrValues.year >
                12 ? (
              <ErrorText
                text="The total GRR rate selected has exceeded 12%."
                className="mt-3"
              />
            ) : (
              <></>
            ))}

          {isNettSellingPrice &&
            (salesPackage?.nettSellingPrice !== 0 &&
            salesPackage?.nettSellingPrice <
              recommendedMaxRebate?.recAbsNettPrice &&
            salesPackage?.nettSellingPrice < totalGrossPrice ? (
              <ErrorText
                text="You have exceeded the maximum allowed rebate, proposal will be submitted
        to management for approval."
                className="mt-3"
              />
            ) : isGrr &&
              salesPackage?.grrValues?.rate &&
              salesPackage?.grrValues?.year &&
              salesPackage?.grrValues.rate * salesPackage?.grrValues.year >
                12 ? (
              <ErrorText
                text="The total GRR rate selected has exceeded 12%."
                className="mt-3"
              />
            ) : (
              <></>
            ))}
        </div>

        <div className="mt-6">
          <h4 className="font-bold mb-4">Complimentary Package</h4>
          <HooksFormCard
            validation={validation}
            defaultValues={defaultValues?.hooks}
          />
        </div>

        <div className="mt-6">
          <Textarea
            id="sales-package-remarks"
            rows={5}
            label="Remarks"
            error={error?.remarks?.message}
            required={false}
            maxLength={500}
            defaultValue={defaultValues?.remarks || ""}
            {...register("salesPackage.remarks")}
          />
        </div>
      </ExpandableCard>
    </>
  );
};

export default SalesPackageAccordian;
