export const proposalStatus = [
  "draft",
  "pending-approval",
  "approved",
  "declined-booking",
  "pending-booking",
  "resubmit-booking",
  "booked",
  "declined-approval",
  "resubmit-approval",
  "sold",
  "expired",
  "withdrawn",
  "unsuccessful",
  "pending-cancellation",
  "cancelled",
] as const;

export type ProposalStatus = typeof proposalStatus[number];

export const proposalStatusName = (status: string): string => {
  const tempProposalStatusName = {
    draft: "Draft",
    "pending-approval": "Pending Approval",
    approved: "Approved",
    "declined-booking": "Declined Booking",
    "pending-booking": "Pending Booking",
    "resubmit-booking": "Resubmit for Booking",
    booked: "Booked",
    "declined-approval": "Declined Approval",
    "resubmit-approval": "Resubmit for Approval",
    sold: "Sold",
    expired: "Expired",
    withdrawn: "Withdrawn",
    unsuccessful: "Unsuccessful",
    "pending-cancellation": "Pending Cancellation",
    cancelled: "Cancelled",
  }[status];

  return tempProposalStatusName ?? "";
};

export const salutations = [
  "Dame",
  "Datin Seri",
  "Datin",
  "Dato Dr.",
  "Dato’",
  "Datuk Seri",
  "Datuk",
  "Dr.",
  "Lord",
  "Master",
  "Miss",
  "Mr",
  "Mrs",
  "Ms",
  "Puan Sri",
  "Sir",
  "Tan Sri",
  "Toh Puan",
  "Tun",
  "Tunku",
  "YB",
  "Ybhg. Dato’",
] as const;

export type Salutation = typeof salutations[number];

export const agencyStatuses = ["active", "inactive", "adhoc"] as const;

export type AgencyStatus = typeof agencyStatuses[number];

export const agentStatuses = ["active", "inactive"] as const;

export type AgentStatus = typeof agentStatuses[number];

export const imageFileTypes = ["jpg", "jpeg", "png", "gif"] as const;

export type ImageFile = typeof imageFileTypes[number];

export const defaultRaces = ["malay", "chinese", "indian"] as const;

export type Race = typeof defaultRaces[number];

export const unitStatus = [
  "available",
  "reserved",
  "blocked",
  "booked",
  "sold",
  "blocked_special",
  "cancelled",
  "cancellation_in_progress",
] as const;

export type UnitStatus = typeof unitStatus[number];

export const consideredSoldUnitStatus = [
  "sold",
  "blocked_special",
  "cancelled",
  "cancellation_in_progress",
] as const;

export type ConsideredSoldUnitStatus = typeof consideredSoldUnitStatus[number];

export const approvalStatus = ["approved", "declined"];

export type ApprovalStatus = typeof approvalStatus[number];
