/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import Path from "../../common/Path";
import {
  Booking,
  Company,
  Customer,
  OpportunitySource,
  PEUnit,
  Proposal,
  SalesPackage,
} from "../../common/Types";
import BackButton from "../../components/Buttons/BackButton";
import FormCard from "../../components/Cards/FormCard";
import BottomBar from "../../components/Navs/BottomBar";
import ProposalSummary from "../../components/ProposalSummary/ProposalSummary";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import LimboPage from "../generals/LimboPage";
import api from "../../services/PabloApiService";
import { ShowProposalResponse } from "../../common/Responses";
import { useMyProposalListContext } from "../../hooks/contexts/MyProposalListContext";
import { determineApprovalStage } from "../../utils/ApprovalUtils";
import { useBookingApprovalListContext } from "../../hooks/contexts/BookingApprovalListContext";

interface LimboPageProps {
  isCE?: boolean;
}

const GenerateLettersViewPage: React.FC<LimboPageProps> = ({
  isCE = false,
}) => {
  const { proposalId } = useParams();
  const myProposal = useMyProposalListContext();
  const bookingApprovalList = useBookingApprovalListContext();
  const loadingPage = useLoadingPageContext();
  const navigate = useNavigate();
  const modal = useModalContext();

  const [proposal, setProposal] = useState<Proposal | undefined>(
    useLocation().state
  );

  const latestApprovals = determineApprovalStage(
    proposal?.allApprovals,
    proposal?.stage
  );

  useEffect(() => {
    if (!proposal) showProposal();
  }, []);

  const showProposal = async () => {
    loadingPage.start();

    const tempProposal = isCE
      ? await bookingApprovalList.show(parseInt(proposalId!))
      : await myProposal.show(parseInt(proposalId!));

    setProposal(tempProposal);

    loadingPage.end();
  };

  if (!proposal) {
    if (isCE && !bookingApprovalList.isLoading)
      return <LimboPage type="not-found" />;

    if (!isCE && !myProposal.isLoading) return <LimboPage type="not-found" />;

    return <></>;
  }

  const showConfirmation = () => {
    modal.confirmation({
      title: "Are you sure you want to generate 7 letters for this proposal?",
      description: "",
      onConfirm: {
        text: "Confirm",
        action: () => generateLetters(),
      },
    });
  };

  const generateLetters = async () => {
    loadingPage.start();

    const { success } = await api.get<ShowProposalResponse>(
      `proposals/generate-letters/${proposalId}/send`
    );

    if (success) {
      isCE ? navigate(Path.bookingApprovals) : navigate(Path.myProposals);
      toast.success("Letters have been generated and sent to your email.");
    }

    loadingPage.end();
  };

  return (
    <>
      {/* page */}
      <div className="col h-full justify-between">
        <div>
          <BackButton
            to={isCE ? Path.bookingApprovals : Path.myProposals}
            text={isCE ? "Back to CE approval listing" : "Back to my proposal"}
          />

          <FormCard showRequired={false} title="">
            <h2 className="mb-4">Proposal Summary</h2>

            <ProposalSummary
              title=""
              summarized={false}
              units={
                [
                  {
                    buildup: proposal?.unit?.type?.buildUp || 0,
                    grossPrice: 0,
                    idTheme: proposal?.unit?.theme,
                    orientation:
                      proposal?.unit?.type?.orientation.toString() as string,
                    pricePsf: 0,
                    status: "",
                    type: proposal?.unit?.type?.name.toString() as string,
                    unitNo: proposal?.unit?.number,
                    level: proposal?.unit?.level,
                  },
                ] as PEUnit[]
              }
              customers={proposal?.customers as Customer[]}
              companies={proposal?.companies as Company[]}
              opportunitySource={
                proposal?.opportunitySource as OpportunitySource
              }
              solicitor={proposal.solicitor}
              salesPicInfo={proposal.user}
              salesPackage={proposal?.salesPackage as SalesPackage}
              bookingInfo={proposal?.booking as Booking}
              remarks={proposal?.remarks}
              approvals={
                latestApprovals?.length
                  ? latestApprovals
                  : determineApprovalStage(proposal?.allApprovals, "creation")
              }
              soldInfo={proposal?.soldDetail}
              cancelledInfo={proposal?.cancelledDetail}
              withdrawnInfo={proposal?.withdrawnDetail}
            />
          </FormCard>
        </div>

        <BottomBar>
          {proposal.status === "booked" && !isCE && (
            <button
              className="secondary-button mr-4"
              onClick={() =>
                navigate(Path.editGenerateLetters(proposal.id), {
                  state: proposal,
                })
              }
            >
              Edit Customer Details
            </button>
          )}

          <button className="primary-button" onClick={() => showConfirmation()}>
            Generate 7 Letters
          </button>
        </BottomBar>
      </div>
    </>
  );
};

export default GenerateLettersViewPage;
