/* eslint-disable react-hooks/exhaustive-deps */
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Path from "../../common/Path";
import {
  defaultStages,
  PaymentStructureType,
  structurableType,
} from "../../common/PaymentStructure";
import { packageableType } from "../../common/SalesPackage";
import {
  FlexibleInstalmentDetails,
  FormErrors,
  Hook,
  PEUnit,
  Rebate,
} from "../../common/Types";
import PaymentStructureAccordian from "../../components/Accordion/PaymentStructureAccordian";
import SalesPackageAccordian from "../../components/Accordion/SalesPackageAccordian";
import BackButton from "../../components/Buttons/BackButton";
import { GroupExpandableCard } from "../../components/Cards/ExpandableCard";
import EditProposalDetailsForm from "../../components/Forms/EditProposalDetailsForm";
import Modal from "../../components/Modals/Modal";
import BottomBar from "../../components/Navs/BottomBar";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import { useMyProposalListContext } from "../../hooks/contexts/MyProposalListContext";
import { useArray } from "../../hooks/UseArray";
import { useScrollToError } from "../../hooks/UseScrollToError";
import { useUnload } from "../../hooks/UseUnload";
import { useValidation } from "../../hooks/UseValidation";
import {
  BookingForm,
  BookingFormSalesPackage,
  salesPackageSchema,
  schema,
} from "../../schemas/BookingSchema";
import {
  calculateRebatePercentage,
  calculateRebateValue,
} from "../../utils/CommonUtils";
import { now } from "../../utils/TimeDateUtils";

const BookingFormPage: React.FC = (props) => {
  const { proposalId } = useParams();
  const isResubmit = !!proposalId;
  const { state } = useLocation();
  const navigate = useNavigate();
  const modal = useModalContext();
  const loadingPage = useLoadingPageContext();
  const validation = useValidation<BookingForm>(schema);
  const { scrollOnError } = useScrollToError<BookingForm>();
  const salesPackageValidation =
    useValidation<BookingFormSalesPackage>(salesPackageSchema);
  const proposalList = useMyProposalListContext();
  const { setDirty } = useUnload();
  const defaultRebate = calculateRebatePercentage(
    state.salesPackage.grossSellingPrice,
    state.salesPackage.nettSellingPrice
  );

  const bookingForm = validation.watch();
  const salesPackageForm = salesPackageValidation.watch();

  const [showEditSalesPackage, setShowEditSalesPackage] =
    useState<boolean>(false);
  const [hasPaymentStructureOpened, setHasPaymentStructureOpened] =
    useState<boolean>(false);
  const [salesPackageIsEdited, setSalesPackageIsEdited] =
    useState<boolean>(false);

  /** Sales Packages States */
  const [formErrors, setFormErrors] = useState<FormErrors>({
    scheduleH: "",
  });

  /** Instalment States */
  const [repaymentMonths, setRepaymentMonths] = useState<number>(
    state.salesPackage?.structurable?.totalRepaymentMonths || 1
  );
  const [paymentStartDate, setPaymentStartDate] = useState<Moment>(
    state.salesPackage?.structurable?.paymentStartDate
      ? moment(state.salesPackage?.structurable?.paymentStartDate)
      : now.clone().add(1, "month")
  );
  const [paymentEndDate, setPaymentEndDate] = useState<Moment>(
    paymentStartDate.clone().add(repaymentMonths, "month")
  );

  /** Schedule H States */
  const [progressRebates, setProgressRebates] = useState<Rebate[]>(
    defaultStages.map((stage: Rebate) => {
      let result = state.salesPackage?.structurable?.rebates?.filter(
        (rebateStage: Rebate) => rebateStage.stage === stage.stage
      );

      if (result && result.length > 0)
        return {
          stage: stage.stage,
          value: result[0].value,
          stringValue: result[0].value.toString(),
        };

      return {
        stage: stage.stage,
        value: stage.value,
        stringValue: stage.stringValue,
      };
    })
  );
  const [remainingRebate, setRemainingRebate] = useState<number>(0);

  /** Payment Structure States */
  const [paymentStructureType, setPaymentStructureType] =
    useState<PaymentStructureType>(
      structurableType(state.salesPackage.structurableType)
    );

  /** Flexible Instalment States */
  const [detailsIndexes, dispatchDetailsIndexes] = useArray<number>(
    state?.salesPackage?.structurable?.details?.length
      ? state?.salesPackage?.structurable?.details?.length > 0
        ? [...state.salesPackage.structurable.details.keys()]
        : [0, 1, 2]
      : [0, 1, 2]
  );

  /** Utilities States */
  const [backupFormData, setBackupFormData] = useState<any>({
    rebatePercentage: defaultRebate,
    nettSellingPrice: state.salesPackage.nettSellingPrice,
    initialPayment: state.salesPackage?.structurable?.initialPayment || 0,
    instalmentPayment: state.salesPackage?.structurable?.instalmentPayment || 0,
    outstandingPayment:
      state.salesPackage?.structurable?.outstandingPayment || 0,
    repaymentMonths: repaymentMonths,
    paymentStartDate: paymentStartDate,
    paymentEndDate: paymentEndDate,
    progressRebates: progressRebates,
    remainingRebate: remainingRebate,
    paymentStructureType: paymentStructureType,
    paymentMethod: state.salesPackage.paymentMethod,
    grrValues: {
      rate: state.salesPackage?.packageable?.rate?.toString(),
      year: state.salesPackage?.packageable?.year?.toString(),
    },
    rebateType: state.salesPackage.rebateType,
    remarks: state.salesPackage?.remarks || "",
    hooks: state.salesPackage?.hooks || [],
    flexibleInstalmentDetails: state.salesPackage?.structurable?.details || [],
    detailsIndexes: detailsIndexes,
  });

  const onPaymentStructureTypeChange = (selectedType: PaymentStructureType) => {
    setPaymentStructureType(selectedType);
    salesPackageValidation.setValue(`paymentStructure.type`, selectedType);
  };

  const handleSubmit = () => {
    setDirty(false);

    modal.confirmation({
      title: "You're about to submit this booking",
      description:
        "You won’t be able to make changes to your rebate amount once you submit your proposal. Please ensure that the details are correct before proceeding with your submission. You will be able to amend the rebate amount by clicking on Edit Sales Package.",
      onConfirm: {
        text: "Submit",
        action: handleFormSubmission,
      },
    });
  };

  const handleFormSubmission = async () => {
    loadingPage.start();
    const { bookingInfo, companyInfo, customerInfo, buyerType } = bookingForm;
    const formData = new FormData();

    /** Buyer Type */
    formData.append("buyer_type", buyerType);

    /** Booking Info */
    bookingInfo?.forEach((booking, index) => {
      booking?.paymentMethod &&
        formData.append(
          `booking[${index}][payment_method]`,
          booking?.paymentMethod
        );
      booking?.currencyCode &&
        formData.append(
          `booking[${index}][currency_code]`,
          booking.currencyCode
        );
      booking?.bookingReceived &&
        formData.append(
          `booking[${index}][amount]`,
          booking?.bookingReceived.toString()
        );
      booking?.remarks &&
        formData.append(`booking[${index}][remarks]`, booking?.remarks);

      /** Booking Proof of Payment */
      booking?.attachments?.forEach((attachment, attachmentIndex) => {
        formData.append(
          `booking[${index}][attachments][${attachmentIndex}][id]`,
          attachment.id
        );
        formData.append(
          `booking[${index}][attachments][${attachmentIndex}][file]`,
          attachment.blob
        );
      });
    });

    if (buyerType === "individual") {
      customerInfo
        ?.filter(() => true)
        ?.forEach((customer, index) => {
          /** Address Info */
          customer?.addressInfo?.addressLine1 &&
            formData.append(
              `buyers[${index}][address][line_1]`,
              customer.addressInfo.addressLine1
            );
          customer?.addressInfo?.addressLine2 &&
            formData.append(
              `buyers[${index}][address][line_2]`,
              customer.addressInfo.addressLine2
            );
          customer?.addressInfo?.city &&
            formData.append(
              `buyers[${index}][address][city]`,
              customer.addressInfo.city
            );
          customer?.addressInfo?.postCode &&
            formData.append(
              `buyers[${index}][address][postcode]`,
              customer.addressInfo.postCode
            );
          customer?.addressInfo?.state &&
            formData.append(
              `buyers[${index}][address][state]`,
              customer.addressInfo.state
            );
          customer?.addressInfo?.country &&
            formData.append(
              `buyers[${index}][address][country]`,
              customer.addressInfo.country
            );

          /** Customer Full Info */
          if (index === 0) {
            state?.customers?.[0]?.id
              ? formData.append(
                  `buyers[${index}][id]`,
                  state.customers[0].id.toString()
                )
              : customer?.id &&
                formData.append(`buyers[${index}][id]`, customer.id.toString());
          } else {
            customer?.id &&
              formData.append(`buyers[${index}][id]`, customer.id.toString());
          }

          customer?.birthDate &&
            formData.append(`buyers[${index}][birth_date]`, customer.birthDate);
          customer?.bumiputera &&
            formData.append(
              `buyers[${index}][bumiputera]`,
              customer.bumiputera === "yes" ? "1" : "0"
            );
          customer?.countryOfOrigin &&
            formData.append(
              `buyers[${index}][country]`,
              customer.countryOfOrigin
            );
          customer?.email &&
            formData.append(`buyers[${index}][email]`, customer.email);
          customer?.fullName &&
            formData.append(`buyers[${index}][full_name]`, customer.fullName);
          customer?.gender &&
            formData.append(`buyers[${index}][gender]`, customer.gender);
          customer?.identificationNumber &&
            formData.append(
              `buyers[${index}][identification_number]`,
              customer.identificationNumber
            );
          customer?.officePhoneCode &&
            formData.append(
              `buyers[${index}][office_phone_code]`,
              customer.officePhoneCode
            );
          customer?.officePhoneNumber &&
            formData.append(
              `buyers[${index}][office_phone_number]`,
              customer.officePhoneNumber
            );
          customer?.phoneCode &&
            formData.append(`buyers[${index}][phone_code]`, customer.phoneCode);
          customer?.phoneNumber &&
            formData.append(
              `buyers[${index}][phone_number]`,
              customer.phoneNumber
            );
          customer?.salutation &&
            formData.append(
              `buyers[${index}][salutation]`,
              customer.salutation
            );

          if (customer?.race) {
            customer.race !== "other" &&
              formData.append(`buyers[${index}][race]`, customer.race);

            customer?.otherRace &&
              customer.race === "other" &&
              formData.append(`buyers[${index}][race]`, customer.otherRace);
          }

          /** Customer Proof of Identity */
          customer?.attachments?.forEach((attachment, attachmentIndex) => {
            formData.append(
              `buyers[${index}][attachments][${attachmentIndex}][id]`,
              attachment.id
            );
            formData.append(
              `buyers[${index}][attachments][${attachmentIndex}][file]`,
              attachment.blob
            );
          });
        });
    }

    if (buyerType === "company") {
      /** Company Address Info */
      companyInfo?.addressInfo?.addressLine1 &&
        formData.append(
          `buyers[0][address][line_1]`,
          companyInfo.addressInfo.addressLine1
        );
      companyInfo?.addressInfo?.addressLine2 &&
        formData.append(
          `buyers[0][address][line_2]`,
          companyInfo.addressInfo.addressLine2
        );
      companyInfo?.addressInfo?.city &&
        formData.append(
          `buyers[0][address][city]`,
          companyInfo.addressInfo.city
        );
      companyInfo?.addressInfo?.postCode &&
        formData.append(
          `buyers[0][address][postcode]`,
          companyInfo.addressInfo.postCode
        );
      companyInfo?.addressInfo?.state &&
        formData.append(
          `buyers[0][address][state]`,
          companyInfo.addressInfo.state
        );
      companyInfo?.addressInfo?.country &&
        formData.append(
          `buyers[0][address][country]`,
          companyInfo.addressInfo.country
        );

      /** Company Info */
      companyInfo?.id &&
        formData.append(`buyers[0][id]`, companyInfo.id.toString());
      companyInfo?.companyName &&
        formData.append(`buyers[0][name]`, companyInfo.companyName);
      companyInfo?.companyRegistrationNumber &&
        formData.append(
          `buyers[0][registration_number]`,
          companyInfo.companyRegistrationNumber
        );
      companyInfo?.companyPhoneCode &&
        formData.append(`buyers[0][phone_code]`, companyInfo.companyPhoneCode);
      companyInfo?.companyPhoneNumber &&
        formData.append(
          `buyers[0][phone_number]`,
          companyInfo.companyPhoneNumber
        );

      /** Company Superform */
      companyInfo?.attachments?.forEach((attachment, index) => {
        formData.append(`buyers[0][attachments][${index}][id]`, attachment.id);
        formData.append(
          `buyers[0][attachments][${index}][file]`,
          attachment.blob
        );
      });

      /** Company Personal Info */
      companyInfo?.personalInfo?.forEach((personalInfo, index) => {
        personalInfo?.id &&
          formData.append(
            `buyers[0][personal_details][${index}][id]`,
            personalInfo.id.toString()
          );
        personalInfo?.title &&
          formData.append(
            `buyers[0][personal_details][${index}][title]`,
            personalInfo.title
          );
        personalInfo?.salutation &&
          formData.append(
            `buyers[0][personal_details][${index}][salutation]`,
            personalInfo.salutation
          );
        personalInfo?.fullName &&
          formData.append(
            `buyers[0][personal_details][${index}][full_name]`,
            personalInfo.fullName
          );
        personalInfo?.email &&
          formData.append(
            `buyers[0][personal_details][${index}][email]`,
            personalInfo.email
          );
        personalInfo?.phoneCode &&
          formData.append(
            `buyers[0][personal_details][${index}][phone_code]`,
            personalInfo.phoneCode
          );
        personalInfo?.phoneNumber &&
          formData.append(
            `buyers[0][personal_details][${index}][phone_number]`,
            personalInfo.phoneNumber
          );
        personalInfo?.identificationNumber &&
          formData.append(
            `buyers[0][personal_details][${index}][identification_number]`,
            personalInfo.identificationNumber
          );

        /** Company Personal Info Proof of Identity */
        personalInfo?.attachments?.forEach((attachment, attachmentIndex) => {
          formData.append(
            `buyers[0][personal_details][${index}][attachments][${attachmentIndex}][id]`,
            attachment.id
          );
          formData.append(
            `buyers[0][personal_details][${index}][attachments][${attachmentIndex}][file]`,
            attachment.blob
          );
        });
      });
    }

    if (salesPackageIsEdited) {
      /** Modified Sales Package */
      formData.append(
        `sales_package[payment_method]`,
        salesPackageForm.salesPackage.paymentMethod
      );
      salesPackageForm?.salesPackage?.nettSellingPrice &&
        formData.append(
          `sales_package[nett_selling_price]`,
          salesPackageForm.salesPackage.nettSellingPrice.toString()
        );
      formData.append(
        `sales_package[rebate_type]`,
        salesPackageForm.salesPackage.rebateType
      );
      salesPackageForm?.salesPackage?.remarks &&
        formData.append(
          `sales_package[remarks]`,
          salesPackageForm?.salesPackage?.remarks
        );
      salesPackageForm?.salesPackage?.hooks?.forEach(
        (hook: Hook, index: number) => {
          hook?.item &&
            formData.append(`sales_package[hooks][${index}][item]`, hook.item);
          hook?.value &&
            formData.append(
              `sales_package[hooks][${index}][value]`,
              hook.value.toString()
            );
        }
      );

      /** Modified Payment Structure */
      if (paymentStructureType === "instalment") {
        formData.append(`sales_package[payment_structure][type]`, "instalment");

        formData.append(
          `sales_package[payment_structure][initial_payment]`,
          salesPackageForm?.paymentStructure?.fixedInstalmentDetails
            ?.initialPayment
            ? salesPackageForm.paymentStructure.fixedInstalmentDetails.initialPayment.toString()
            : "0"
        );

        formData.append(
          `sales_package[payment_structure][instalment_payment]`,
          salesPackageForm?.paymentStructure?.fixedInstalmentDetails
            ?.instalmentPayment
            ? salesPackageForm.paymentStructure.fixedInstalmentDetails.instalmentPayment.toString()
            : "0"
        );

        formData.append(
          `sales_package[payment_structure][outstanding_payment]`,
          salesPackageForm?.paymentStructure?.fixedInstalmentDetails
            ?.outstandingPayment
            ? salesPackageForm.paymentStructure.fixedInstalmentDetails.outstandingPayment.toString()
            : "0"
        );

        formData.append(
          `sales_package[payment_structure][payment_start_date]`,
          paymentStartDate.clone().startOf("month").format("YYYY-MM-DD")
        );
        formData.append(
          `sales_package[payment_structure][total_repayment_months]`,
          repaymentMonths.toString()
        );
      }

      if (paymentStructureType === "flexible-instalment") {
        formData.append(
          `sales_package[payment_structure][type]`,
          "flexible-instalment"
        );
        salesPackageForm?.paymentStructure?.flexibleInstalmentDetails
          ?.filter((detail: FlexibleInstalmentDetails) => detail !== null)
          ?.forEach((detail: FlexibleInstalmentDetails, index: number) => {
            detail?.item &&
              formData.append(
                `sales_package[payment_structure][details][${index}][item]`,
                detail.item
              );
            detail?.datePayable &&
              formData.append(
                `sales_package[payment_structure][details][${index}][date_payable]`,
                detail.datePayable
              );
            detail?.amountPayable &&
              formData.append(
                `sales_package[payment_structure][details][${index}][amount_payable]`,
                detail.amountPayable.toString()
              );
            detail?.remarks &&
              formData.append(
                `sales_package[payment_structure][details][${index}][remarks]`,
                detail.remarks
              );
          });
      }

      if (paymentStructureType === "schedule-h") {
        formData.append(`sales_package[payment_structure][type]`, "schedule-h");
        progressRebates
          .filter((rebate) => rebate.value > 0)
          .forEach((rebate, index) => {
            rebate?.stage &&
              formData.append(
                `sales_package[payment_structure][rebates][${index}][stage]`,
                rebate.stage
              );
            rebate?.value &&
              formData.append(
                `sales_package[payment_structure][rebates][${index}][value]`,
                rebate.value.toString()
              );
          });
      }
    } else {
      /** Unmodified Sales Package */
      formData.append(
        `sales_package[payment_method]`,
        state.salesPackage.paymentMethod
      );
      formData.append(
        `sales_package[nett_selling_price]`,
        state.salesPackage.nettSellingPrice.toString()
      );
      formData.append(
        `sales_package[rebate_type]`,
        state.salesPackage.rebateType
      );
      state.salesPackage?.remarks &&
        formData.append(`sales_package[remarks]`, state.salesPackage.remarks);
      state.salesPackage?.hooks?.forEach((hook: Hook, index: number) => {
        hook?.item &&
          formData.append(`sales_package[hooks][${index}][item]`, hook.item);
        hook?.value &&
          formData.append(
            `sales_package[hooks][${index}][value]`,
            hook.value.toString()
          );
      });

      /** Unmodified Payment Structure */
      if (paymentStructureType === "instalment") {
        formData.append(`sales_package[payment_structure][type]`, "instalment");

        formData.append(
          `sales_package[payment_structure][initial_payment]`,
          state.salesPackage?.structurable?.initialPayment
            ? state.salesPackage.structurable.initialPayment.toString()
            : "0"
        );

        formData.append(
          `sales_package[payment_structure][instalment_payment]`,
          state.salesPackage?.structurable?.instalmentPayment
            ? state.salesPackage.structurable.instalmentPayment.toString()
            : "0"
        );

        formData.append(
          `sales_package[payment_structure][outstanding_payment]`,
          state.salesPackage?.structurable?.outstandingPayment
            ? state.salesPackage.structurable.outstandingPayment.toString()
            : "0"
        );

        formData.append(
          `sales_package[payment_structure][payment_start_date]`,
          state.salesPackage.structurable.paymentStartDate
        );
        formData.append(
          `sales_package[payment_structure][total_repayment_months]`,
          state.salesPackage.structurable.totalRepaymentMonths.toString()
        );
      }

      if (paymentStructureType === "flexible-instalment") {
        formData.append(
          `sales_package[payment_structure][type]`,
          "flexible-instalment"
        );
        state.salesPackage?.structurable?.details
          ?.filter((detail: FlexibleInstalmentDetails) => detail !== null)
          ?.forEach((detail: FlexibleInstalmentDetails, index: number) => {
            detail?.item &&
              formData.append(
                `sales_package[payment_structure][details][${index}][item]`,
                detail.item
              );
            detail?.datePayable &&
              formData.append(
                `sales_package[payment_structure][details][${index}][date_payable]`,
                detail.datePayable
              );
            detail?.amountPayable &&
              formData.append(
                `sales_package[payment_structure][details][${index}][amount_payable]`,
                detail.amountPayable.toString()
              );
            detail?.remarks &&
              formData.append(
                `sales_package[payment_structure][details][${index}][remarks]`,
                detail.remarks
              );
          });
      }

      if (paymentStructureType === "schedule-h") {
        formData.append(`sales_package[payment_structure][type]`, "schedule-h");
        state.salesPackage?.structurable?.rebates?.forEach(
          (rebate: Rebate, index: number) => {
            rebate?.stage &&
              formData.append(
                `sales_package[payment_structure][rebates][${index}][stage]`,
                rebate.stage
              );
            rebate?.value &&
              formData.append(
                `sales_package[payment_structure][rebates][${index}][value]`,
                rebate.value.toString()
              );
          }
        );
      }
    }

    const result = isResubmit
      ? await proposalList.resubmit(state.id, formData)
      : await proposalList.book(state.id, formData);

    loadingPage.end();

    if (result) toast.success("Your proposal has been submitted.");
    else toast.error("Booking submission failed");

    navigate(Path.myProposals);
  };

  const handleCustomValidation = () => {
    let errorCount = 0;
    let errors = formErrors;
    let tempRebate =
      salesPackageForm?.salesPackage?.rebateType === "percentage"
        ? salesPackageForm?.salesPackage?.rebate
        : calculateRebateValue(
            state.salesPackage.grossSellingPrice,
            salesPackageForm?.salesPackage?.nettSellingPrice
          );
    let tempRemainingRebate =
      tempRebate -
      progressRebates.reduce((inputRebate: number, progressRebate: Rebate) => {
        let tempTotalRebate = inputRebate + progressRebate.value;
        return Math.round(tempTotalRebate * 100) / 100;
      }, 0);

    if (paymentStructureType === "schedule-h" && tempRemainingRebate > 0) {
      errors.scheduleH = "Please apply all the remaining rebate";
      errorCount++;
    } else if (
      paymentStructureType === "schedule-h" &&
      tempRemainingRebate < 0
    ) {
      errors.scheduleH =
        "Rebate has exceeded the maximum rebate amount selected";
      errorCount++;
    } else {
      errors.scheduleH = "";
    }

    setFormErrors(errors);
    return errorCount > 0 ? true : false;
  };

  const onSaveSalesPackage = async () => {
    setHasPaymentStructureOpened(true);
    const hasErrors = handleCustomValidation();
    const result = await salesPackageValidation.trigger();

    if (
      result &&
      Object.keys(salesPackageValidation.errors).length === 0 &&
      !hasErrors
    ) {
      const salesPackageFixedForm = salesPackageValidation.getValues();

      setBackupFormData({
        rebatePercentage: salesPackageFixedForm?.salesPackage?.rebate,
        nettSellingPrice: salesPackageFixedForm?.salesPackage?.nettSellingPrice,
        initialPayment:
          salesPackageFixedForm?.paymentStructure?.fixedInstalmentDetails
            ?.initialPayment,
        instalmentPayment:
          salesPackageFixedForm?.paymentStructure?.fixedInstalmentDetails
            ?.instalmentPayment,
        outstandingPayment:
          salesPackageFixedForm?.paymentStructure?.fixedInstalmentDetails
            ?.outstandingPayment,
        repaymentMonths: repaymentMonths,
        paymentStartDate: paymentStartDate,
        paymentEndDate: paymentEndDate,
        progressRebates: progressRebates,
        remainingRebate: remainingRebate,
        paymentStructureType: paymentStructureType,
        paymentMethod: salesPackageFixedForm?.salesPackage?.paymentMethod,
        grrValues: salesPackageFixedForm?.salesPackage?.grrValues,
        rebateType: salesPackageFixedForm?.salesPackage?.rebateType,
        remarks: salesPackageFixedForm?.salesPackage?.remarks,
        hooks: salesPackageFixedForm?.salesPackage?.hooks,
        flexibleInstalmentDetails:
          salesPackageFixedForm?.paymentStructure?.flexibleInstalmentDetails?.map(
            (detail: FlexibleInstalmentDetails) => {
              return {
                item: detail.item,
                datePayable: detail.datePayable,
                amountPayable: detail.amountPayable,
                remarks: detail?.remarks,
              };
            }
          ),
        detailsIndexes: detailsIndexes.filter((v) => v !== null),
      });

      toast.success("Sales Package has been saved successfully");
      setSalesPackageIsEdited(true);
      setShowEditSalesPackage(false);
    }
  };

  const onCancelSalesPackage = () => {
    setFormErrors({
      scheduleH: "",
    });
    setRepaymentMonths(backupFormData?.repaymentMonths || 1);
    setPaymentStartDate(backupFormData?.paymentStartDate);
    setPaymentEndDate(backupFormData?.paymentEndDate);
    setProgressRebates(backupFormData?.progressRebates);
    setRemainingRebate(backupFormData?.remainingRebate);
    setPaymentStructureType(backupFormData?.paymentStructureType);
    setHasPaymentStructureOpened(false);

    salesPackageValidation.setValue(
      "salesPackage.paymentMethod",
      backupFormData?.paymentMethod
    );

    salesPackageValidation.setValue(
      "salesPackage.grrValues",
      backupFormData?.grrValues
    );

    salesPackageValidation.setValue(
      "salesPackage.rebate",
      backupFormData?.rebatePercentage
    );

    salesPackageValidation.setValue(
      "salesPackage.nettSellingPrice",
      backupFormData?.nettSellingPrice
    );

    salesPackageValidation.setValue(
      "salesPackage.rebateType",
      backupFormData?.rebateType
    );

    salesPackageValidation.setValue(
      "salesPackage.remarks",
      backupFormData?.remarks || ""
    );

    salesPackageValidation.resetField(`salesPackage.hooks`);

    backupFormData?.hooks.forEach((hook: Hook, index: number) => {
      salesPackageValidation.setValue(
        `salesPackage.hooks.${index}.item`,
        hook.item
      );

      salesPackageValidation.setValue(
        `salesPackage.hooks.${index}.value`,
        hook.value
      );
    });

    salesPackageValidation.setValue(
      `paymentStructure.type`,
      paymentStructureType
    );

    salesPackageValidation.setValue(
      `paymentStructure.fixedInstalmentDetails.initialPayment`,
      backupFormData?.initialPayment || 0
    );

    salesPackageValidation.setValue(
      `paymentStructure.fixedInstalmentDetails.instalmentPayment`,
      backupFormData?.instalmentPayment || 0
    );

    salesPackageValidation.setValue(
      `paymentStructure.fixedInstalmentDetails.outstandingPayment`,
      backupFormData?.outstandingPayment || 0
    );

    salesPackageValidation.resetField(
      `paymentStructure.flexibleInstalmentDetails`,
      {
        defaultValue: [],
      }
    );

    backupFormData?.flexibleInstalmentDetails?.forEach(
      (detail: FlexibleInstalmentDetails, index: number) => {
        salesPackageValidation.resetField(
          `paymentStructure.flexibleInstalmentDetails.${index}.item`,
          {
            defaultValue: detail.item,
          }
        );

        salesPackageValidation.resetField(
          `paymentStructure.flexibleInstalmentDetails.${index}.datePayable`,
          {
            defaultValue: detail.datePayable,
          }
        );

        salesPackageValidation.resetField(
          `paymentStructure.flexibleInstalmentDetails.${index}.amountPayable`,
          {
            defaultValue: detail.amountPayable,
          }
        );

        salesPackageValidation.resetField(
          `paymentStructure.flexibleInstalmentDetails.${index}.remarks`,
          {
            defaultValue: detail?.remarks,
          }
        );
      }
    );

    dispatchDetailsIndexes({
      type: "replace",
      value: 0,
      arrValue: backupFormData?.detailsIndexes?.length
        ? backupFormData.detailsIndexes
        : [0, 1, 2],
    });

    setShowEditSalesPackage(false);
  };

  useEffect(() => {
    salesPackageValidation.setValue(`salesPackage.rebate`, defaultRebate);
    salesPackageValidation.setValue(
      `salesPackage.nettSellingPrice`,
      state.salesPackage.nettSellingPrice
    );
    salesPackageValidation.setValue(
      `paymentStructure.type`,
      paymentStructureType
    );
  }, []);

  useEffect(() => {
    scrollOnError(validation.errors);
  }, [validation.errors]);

  return (
    <>
      {/* page */}
      <div className="col justify-between min-h-screen h-max">
        <div className="mb-20">
          <BackButton text="Back to my proposal" />

          <div className="h-14">
            <h2 className="capitalize mx-7">Booking Form</h2>
          </div>

          <EditProposalDetailsForm
            proposal={state}
            validation={validation}
            setDirty={setDirty}
            hasBooking={true}
          />
        </div>
      </div>

      <BottomBar>
        <button
          id="edit-sales-package-button"
          className="secondary-button mr-4"
          onClick={() => setShowEditSalesPackage(true)}
        >
          Edit Sales Package
        </button>

        <button
          id="submit-booking-button"
          className="primary-button"
          onClick={validation.handleSubmit(handleSubmit)}
        >
          Submit Booking
        </button>
      </BottomBar>

      <Modal
        show={showEditSalesPackage}
        onHide={() => {}}
        dismissible={false}
        containerClassName="w-10/12 h-[90%]"
        modalClassName="w-full px-5 pt-5"
      >
        <div className="overflow-auto pb-5 h-full">
          <div className="text-center">
            <h2 className="capitalize">Edit Sales Package</h2>
          </div>
          <GroupExpandableCard
            defaultValue="sales-packages"
            onExpand={(accordian) =>
              accordian === "payment-structure"
                ? setHasPaymentStructureOpened(true)
                : {}
            }
          >
            <SalesPackageAccordian
              validation={salesPackageValidation}
              hasSalesPackageOpened={true}
              units={
                [
                  {
                    buildup: state.unit.type.buildUp || 0,
                    grossPrice: 0,
                    idTheme: state.unit.theme,
                    orientation: state.unit.type.orientation,
                    pricePsf: 0,
                    status: state.unit.status,
                    type: state.unit.type.name as string,
                    unitNo: state.unit.number,
                    level: state.unit.level,
                  },
                ] as PEUnit[]
              }
              defaultValues={{
                rebate: 0,
                nettSellingPrice: 0,
                selectedSalesPackage: packageableType(
                  salesPackageIsEdited
                    ? salesPackageForm?.salesPackage?.selectedSalesPackage
                    : state.salesPackage.packageableType
                ),
                grrValues: {
                  rate: salesPackageIsEdited
                    ? salesPackageForm?.salesPackage?.grrValues?.rate?.toString()
                    : state?.salesPackage?.packageable?.rate?.toString(),
                  year: salesPackageIsEdited
                    ? salesPackageForm?.salesPackage?.grrValues?.year?.toString()
                    : state?.salesPackage?.packageable?.numberOfYears?.toString(),
                },
                paymentMethod: salesPackageIsEdited
                  ? salesPackageForm?.salesPackage?.paymentMethod
                  : state.salesPackage.paymentMethod,
                grossSellingPrice: state.salesPackage.grossSellingPrice,
                recommendedMaxRebate: {
                  maxRecDisc: state.salesPackage.recommendedMaxRebate,
                  recAbsNettPrice:
                    state.salesPackage.recommendedNettSellingPrice,
                },
                rebateType: salesPackageIsEdited
                  ? salesPackageForm?.salesPackage?.rebateType
                  : state.salesPackage.rebateType,
                hooks: salesPackageIsEdited
                  ? salesPackageForm?.salesPackage?.hooks
                  : state.salesPackage.hooks,
                remarks: salesPackageIsEdited
                  ? salesPackageForm?.salesPackage?.remarks
                  : state.salesPackage.remarks,
              }}
              className="shadow-none mx-0 mt-4 mb-0 border border-[#53565A33]"
            />

            <PaymentStructureAccordian
              validation={salesPackageValidation}
              type={paymentStructureType}
              onTypeChange={onPaymentStructureTypeChange}
              formErrors={formErrors}
              tower={state.unit.number.charAt(0)}
              nettSellingPrice={
                salesPackageForm?.salesPackage?.nettSellingPrice ||
                state.salesPackage.nettSellingPrice
              }
              repaymentMonths={repaymentMonths}
              paymentStartDate={paymentStartDate}
              paymentEndDate={paymentEndDate}
              hasPaymentStructureOpened={hasPaymentStructureOpened}
              setFormErrors={setFormErrors}
              setRepaymentMonths={setRepaymentMonths}
              setPaymentStartDate={setPaymentStartDate}
              setPaymentEndDate={setPaymentEndDate}
              totalRebate={
                salesPackageForm?.salesPackage?.rebateType === "percentage"
                  ? salesPackageForm?.salesPackage?.rebate
                  : calculateRebateValue(
                      state.salesPackage.grossSellingPrice,
                      salesPackageForm?.salesPackage?.nettSellingPrice
                    )
              }
              remainingRebate={remainingRebate}
              progressRebates={progressRebates}
              setProgressRebates={setProgressRebates}
              setRemainingRebate={setRemainingRebate}
              className="shadow-none mx-0 mt-4 mb-0 border border-[#53565A33]"
              hasDefaultValues={true}
              grossSellingPrice={state.salesPackage.grossSellingPrice}
              rebateType={salesPackageForm?.salesPackage?.rebateType}
              defaultPaymentStructure={state.salesPackage.structurable}
              detailsIndexes={detailsIndexes}
              dispatchDetailsIndexes={dispatchDetailsIndexes}
            />
          </GroupExpandableCard>
        </div>

        <BottomBar innerClassName="pr-0 gap-4">
          <button
            id="cancel-sales-package-button"
            className="secondary-button"
            onClick={onCancelSalesPackage}
          >
            Cancel
          </button>
          <button
            id="save-sales-package-button"
            className="primary-button"
            onClick={onSaveSalesPackage}
          >
            Save
          </button>
        </BottomBar>
      </Modal>
    </>
  );
};

export default BookingFormPage;
